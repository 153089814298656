import React from 'react';
import styled, { css } from 'styled-components';
import { rem, utils, flex, palette, media } from 'react-c2c-ui';
import { MdKeyboardBackspace } from 'react-icons/md';
import { RouteComponentProps, withRouter } from 'react-router';

interface OwnProps {
    backText: string;
    headerText: string;
    backTo: string;
}
type Props = OwnProps&RouteComponentProps;

const HeaderTemplate: React.SFC<Props> = ({backText, headerText, backTo, history}) => {
    const handleClickBack = () => {
        history.push(backTo);
    }
    return (
        <HeaderTemplateBlock>
            <div className="back" onClick={handleClickBack}>
                <MdKeyboardBackspace/>
                <p>{backText}</p>
            </div>

            <h1>{headerText}</h1>
        </HeaderTemplateBlock>
    )
}

const HeaderTemplateBlock = styled.div`
    >.back {
        ${flex.row};
        ${flex.ai.center};
        margin-bottom: ${rem(40)};
        cursor: pointer;
        >svg {
            font-size: ${rem(24)};
            color: ${palette.blue500};
        }

        >p {
            font-size: ${rem(14)};
            margin-left: ${rem(10)};
        }

        &:hover {
            >svg {
                color: ${palette.blue600};
            }

            >p {
                color: ${palette.blue500};
            }
        }
    }

    >h1 {
        font-size: ${rem(36)};
    }
`;

export default withRouter(HeaderTemplate);