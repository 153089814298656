import {useEffect, useState, useCallback} from 'react';
import {AxiosPromise} from 'axios';

type PromiseCreator<R> = (...params: any[]) => AxiosPromise<R>;
type ReturnType<R> = [
    Function,
    R|null,
    boolean,
    string
]

export default function useRequest<R = any>(
    func: PromiseCreator<R>
):ReturnType<R> {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<R | null>(null);
    const [error, setError] = useState("");

    const onRequest = useCallback(
        async (...params: any) => {
            setError("");
            try {
                setLoading(true);
                const res = await func(...params);
                setData(res.data);
                setLoading(false);
                return res.data;
            } catch (error) {
                if(error.response) {
                    console.error('error.response', error.response)
                    setError(error.response.data);
                } else {
                    console.error('error', error)
                    setError("SERVER_ERROR");
                }
                setLoading(false);
                throw error;
            }
            
        },
        []
    );

    return [onRequest, data, loading, error];
}
