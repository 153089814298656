import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components';
import { rem, utils, flex, palette, media } from 'react-c2c-ui';
import useRequest from 'lib/hooks/useRequest';
import {TableBox} from 'containers/samplePack/CuppingInfo';
import Pagination from 'components/_elements/Pagination';
import { links } from 'lib/links';
import { RouteComponentProps, withRouter } from 'react-router';
import { useLocation } from "react-router-dom";
import cAxios from 'lib/axiosInstance';
import { convertCountryCodeToName } from 'lib/nameGenerator';
import {mkSellerCoffeeStatus} from 'lib/status';


interface OwnProps {

}
type Props = OwnProps&RouteComponentProps;
const TAKE = 15;
const CoffeeListPage: React.SFC<Props> = ({history}) => {
    const [
        onRequestGetCoffeeList,
        getCoffeeListResult,
        getCoffeeListLoading,
        getCoffeeListError
    ] = useRequest<any>((page)=>
        cAxios.get('/coffee/mgmt', {params: {page, take: TAKE}})
    );
    const [pageCount, setPageCount] = useState(1);
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    let page = query.get('page')?Number(query.get('page')):1;
    const getCoffeeList = async (page: number) => {
        const data = await onRequestGetCoffeeList(page);
        const totalPages = Math.ceil(data.totalCount/TAKE);
        setPageCount(totalPages);
    }

    useEffect(() => {
        getCoffeeList(page);
        console.log('Number(query.get(page))', Number(query.get('page')))
    }, [page])

    const handlePageChange = async (data:any) => {
        history.push(links.coffeeList+`?page=${data.selected+1}`)
    };
    if(getCoffeeListResult) {
        return (
            <div>
                <CoffeeListPageBlock>
                    <TableBox>
                        <table>
                            <thead>
                                <tr>
                                    <th>id</th>
                                    <th>seller</th>
                                    <th>origin</th>
                                    <th>region</th>
                                    <th>processing</th>
                                    <th>varieties</th>
                                    <th>grade</th>
                                    <th>price</th>
                                    <th>stock</th>
                                    <th>moq</th>
                                    <th>Sample Pack status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getCoffeeListResult.coffees.map((item:any) => {
                                    const {
                                        id,
                                        selectedSamplePackId,
                                        userId,
                                        evaluationId,
                                        farmId,
                                        status,
                                        deprecated,
                                        processing,
                                        grade,
                                        harvest,
                                        screenSize,
                                        image,
                                        story,
                                        packaging,
                                        bagSize,
                                        price,
                                        moq,
                                        stock,
                                        createdAt,
                                        updatedAt,
                                        farm = {},
                                        varieties = [],
                                        certifications = [],
                                        samplePacks = [],
                                        user = {},
                                    } = item;

                                    const {
                                        country,
                                        region,
                                        altitude,
                                        temperature,
                                        soil,
                                        annualRainfall
                                    } = farm;

                                    const farmName = farm.name;
                            
                                    const {
                                        name,
                                        email,
                                        seller = {}
                                    } = user;
                            
                                    // const {
                                    //     companyEmail,
                                    //     tin,
                                    //     website,
                                    //     cellPhoneNumber,
                                    //     managerPhoneNumber,
                                    //     logoImage,
                                    //     backgroundImage,
                                    //     managerName,
                                    //     managerEmail,
                                    //     managerCellPhoneNumber,
                                    //     bankName,
                                    //     bankBranch,
                                    //     bankAccount,
                                    //     recipient,
                                    //     swiftCode,
                                    //     bankAddress
                                    // } = seller;

                                    const companyName = seller?seller.companyName:'NO_COMPANY_NAME'
                                    return (
                                        <StyledTr
                                            key={id}
                                            onClick={() => {
                                                history.push(
                                                    links.coffeeDetail.replace(':id', id),
                                                    {page}
                                                )
                                            }}
                                            deprecated={deprecated}
                                        >
                                            <td title={`${id}${deprecated?': It\'s deprecated':''}`}>{id}</td>
                                            <td title={companyName}><p style={{width: '7.5rem'}} className="single-ellipsis">{companyName}</p></td>
                                            <td title={convertCountryCodeToName(country)}>{country}</td>
                                            <td title={region}><p style={{width: '6.25rem'}} className="single-ellipsis">{region}</p></td>
                                            <td title={processing}><p className="single-ellipsis">{processing}</p></td>
                                            <td title={varieties.map((item:any) => item.variety).join(', ')}>
                                                <p style={{width: '6.25rem'}}className="single-ellipsis">
                                                    {varieties.map((item:any) => item.variety).join(', ')}
                                                </p>
                                            </td>
                                            <td title={grade}>{grade}</td>
                                            <td title={price}>{price}</td>
                                            <td title={stock}>{stock}</td>
                                            <td title={moq}>{moq}</td>
                                            <td title={
                                                mkSellerCoffeeStatus(
                                                false,
                                                samplePacks,
                                                selectedSamplePackId
                                            )}>
                                                {mkSellerCoffeeStatus(
                                                    false,
                                                    samplePacks,
                                                    selectedSamplePackId
                                                )}
                                            </td>
                                        </StyledTr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </TableBox>
                </CoffeeListPageBlock>
                <Pagination
                    handlePageChange={handlePageChange}
                    pageCount={pageCount}
                    currentPage={page-1}
                />
            </div>
        )
    } else {
        return <p>loading...</p>
    }
}

const CoffeeListPageBlock = styled.div`
    height: 80vh;
    overflow: auto;
    margin-bottom: ${rem(20)};

    ${`` /**remove TableBox styled component style */}
    >:first-child {
        overflow-x: initial;
    }

`;

const StyledTr = styled.tr<{deprecated:boolean}>`
    cursor: pointer;    
    &:hover {
        background: ${palette.gray200};
    }
    ${props => props.deprecated && css`
        background: ${palette.red500};
        &:hover {
            background: ${palette.red600};
        }
    `};
`;

export default withRouter(CoffeeListPage);