export const mkCuppingData = (cuppings) => {
    if(cuppings.length<1) {
        return {
            averageItems:{},
            cuppingScore:null
        }
    }
    let balanceTotal = 0;
    let fragranceTotal = 0;
    let flavorTotal = 0;
    let afterTasteTotal = 0;
    let acidityTotal = 0;
    let bodyTotal = 0;
    let uniformityTotal = 0;
    let sweetnessTotal = 0;
    let cleanCupTotal = 0;
    let overallTotal = 0;

    const listLength = cuppings.length;

    cuppings.map(item => {
        balanceTotal += Number(item.balance);
        fragranceTotal += Number(item.fragrance);
        flavorTotal += Number(item.flavor);
        afterTasteTotal += Number(item.afterTaste);
        acidityTotal += Number(item.acidity);
        bodyTotal += Number(item.body);
        uniformityTotal += Number(item.uniformity);
        sweetnessTotal += Number(item.sweetness);
        cleanCupTotal += Number(item.cleanCup);
        overallTotal += Number(item.overall);
    })

    const allTotal = (
        balanceTotal+
        fragranceTotal+
        flavorTotal+
        afterTasteTotal+
        acidityTotal+
        bodyTotal+
        uniformityTotal+
        sweetnessTotal+
        cleanCupTotal+
        overallTotal
    )

    return {
        total: (allTotal).toFixed(2),
        averageItems: {
            balance:(balanceTotal/listLength).toFixed(2),
            fragrance:(fragranceTotal/listLength).toFixed(2),
            flavor:(flavorTotal/listLength).toFixed(2),
            afterTaste:(afterTasteTotal/listLength).toFixed(2),
            acidity:(acidityTotal/listLength).toFixed(2),
            body:(bodyTotal/listLength).toFixed(2),
            uniformity:(uniformityTotal/listLength).toFixed(2),
            sweetness:(sweetnessTotal/listLength).toFixed(2),
            cleanCup:(cleanCupTotal/listLength).toFixed(2),
            overall:(overallTotal/listLength).toFixed(2),
        },
        cuppingScore: (allTotal/listLength).toFixed(2)
    }
}

export const mkC2CScore = (harvest, greenGrading, screenSize, cuppingScore) => {
    const {
        density, moisture, createdAt,
        fullBlack, fullSour, driedCherryAndPod, fungusDamaged, severeInsectDamaged, foreignMatter,

        partialBlack, partialSour, parchment, floater, immatureAndUnripe, withered, shell, brokenAndChippedAndCut, hullAndHusk, slightInsectDamaged
    } = greenGrading;
    const today = new Date();
    const todayYear = Number(String(today.getFullYear()).substr(2,2));
    const todayMonth = Number(String(today.getMonth()).substr(0,2))+1;
    const harvestDate = harvest.split("~")[0].split(".")
    const harvestYear = Number(String(harvestDate[0]).substr(2,2));
    const harvestMonth = Number(harvestDate[1]);
    console.log('todayYear :', todayYear);
    console.log('todayMonth :', todayMonth);
    console.log('harvestYear :', harvestYear);
    console.log('harvestMonth :', harvestMonth);
    let difference = 0;
    let harvestCost = 0;
    if(todayYear>harvestYear) {
        difference = ((12*(todayYear-harvestYear))-harvestMonth)+todayMonth;
    } else if(todayYear===harvestYear) {
        difference = todayMonth-harvestMonth;
    } else {
        console.error('harvest date is wrong');
    }
    console.log('month of difference from harvest: ', difference, 'months');
    if(difference<0) {
        console.error('today or harvest date is wrong');
    }
    if(difference<5) {
        harvestCost=100;
    } else if(difference<9) {
        harvestCost=80;
    } else if(difference<13) {
        harvestCost=60;
    } else if(difference<17) {
        harvestCost=40;
    } else if(difference<21) {
        harvestCost=20;
    } else if(difference<25) {
        harvestCost=0;
    } else {
        harvestCost=0;
    }
    console.log('harvestCost :', harvestCost);

    let cuppingCost = 0;
    if(cuppingScore>100) {
        console.log('cuppingScore is higher than 100, cuppingScore :', cuppingScore);
    }
    if(cuppingScore>=91) {
        cuppingCost=100;
    } else if(cuppingScore>=88) {
        cuppingCost=97;
    } else if(cuppingScore>=85) {
        cuppingCost=92;
    } else if(cuppingScore>=82) {
        cuppingCost=85;
    } else if(cuppingScore>=79) {
        cuppingCost=76;
    } else if(cuppingScore>=76) {
        cuppingCost=65;
    } else if(cuppingScore>=73) {
        cuppingCost=52;
    } else if(cuppingScore>=70) {
        cuppingCost=37;
    } else if(cuppingScore>=67) {
        cuppingCost=19;
    } else {
        cuppingCost=19;
    }

    console.log('cuppingCost :', cuppingCost);

    const fullDefectsNumber = (
        Math.floor(fullBlack/1)+
        Math.floor(fullSour/1)+
        Math.floor(driedCherryAndPod/1)+
        Math.floor(fungusDamaged/1)+
        Math.floor(foreignMatter),
        Math.floor(severeInsectDamaged/5)+

        Math.floor(partialBlack/3)+
        Math.floor(partialSour/3)+
        Math.floor(parchment/5)+
        Math.floor(floater/5)+
        Math.floor(immatureAndUnripe/5)+
        Math.floor(withered/5)+
        Math.floor(shell/5)+
        Math.floor(brokenAndChippedAndCut/5)+
        Math.floor(hullAndHusk/5)+
        Math.floor(slightInsectDamaged/10)
    )
    let fullDefectsCost = 0;

    if(fullDefectsNumber<1) {
        fullDefectsCost=100;
    } else if(fullDefectsNumber<2) {
        fullDefectsCost=80;
    } else if(fullDefectsNumber<3) {
        fullDefectsCost=60;
    } else if(fullDefectsNumber<4) {
        fullDefectsCost=40;
    } else if(fullDefectsNumber<5) {
        fullDefectsCost=20;
    } else {
        fullDefectsCost=0;
    }

    console.log('fullDefectsNumber :', fullDefectsNumber);
    console.log('fullDefectsCost :', fullDefectsCost);

    let densityCost = 0;
    if(density>900) {
        console.log('density higher than 900, density :', density);
    } else if(density>840) {
        densityCost = 100;
    } else if(density>780) {
        densityCost = 80;
    } else if(density>720) {
        densityCost = 60;
    } else if(density>660) {
        densityCost = 40;
    } else if(density>600) {
        densityCost = 20;
    } else if(density>540) {
        densityCost = 0;
    } else {
        densityCost = 0;
    }
    const c2cScore = (
        harvestCost*0.1+
        cuppingCost*0.3+
        densityCost*0.1+
        fullDefectsCost*0.25+
        25
    )
    console.log('c2cScore :', c2cScore);
    return {
        c2cScore,
        costs:{
            harvestCost,
            cuppingCost,
            densityCost,
            fullDefectsCost,
            screenSizeCofe:'100(temp)'
        }
    }

}