import React, { useState, forwardRef, ReactNode, SFC } from 'react'
import styled, { css } from 'styled-components';
import { rem, utils, flex, palette, media } from 'react-c2c-ui';
import { createPortal } from 'react-dom';
interface OwnProps {
    id: string;
    evaluatedDate: string;
}
type Props = OwnProps;

const PaperWrapper:SFC<Props> = ({children, id, evaluatedDate}) => {
    return createPortal(
        <PaperWrapperBlock id={id}>
                    <Header id="fixed-header">
                        <div className="logo">
                            <img src={'https://c2cplatform.s3.ap-northeast-2.amazonaws.com/publicImg/blue_black.png'} alt=""/>
                            <p>Evaluation Date: {evaluatedDate}</p>
                        </div>
                    </Header>
                    <Footer id="fixed-footer">
                        <div className='footer'>
                            <p>ⓒ 2020 C2C Platform Co., Ltd. All Rights Reserved.</p>
                        </div>
                    </Footer>
            {children}
        </PaperWrapperBlock>, document.body
    )
}

const PaperWrapperBlock = styled.div`
    width: 210mm;
    margin: 0 auto;
    z-index: -1;
`;

const Header = styled.div`
    display: none;
    width: 210mm;
    height: 30mm;
    position: fixed;
    top: 0;
    >.logo {
        position: relative;
        height: 30mm;
        display: flex;
        justify-content: center;
        align-items: center;
        >img{
            height: 10mm;
        }

        >p {
            position: absolute;
            bottom: 5mm;
            right: 10mm;
        }
    }
`;

const Footer = styled.div`
    display: none;
    width: 210mm;
    height: 10mm;
    background: ${palette.gray100};
    position: fixed;
    bottom: 0;
    >.footer {
        height: 10mm;
        display: flex;
        justify-content: center;
        align-items: center;

    }
`;

export default PaperWrapper;