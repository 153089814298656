import React from 'react';
import _ from 'lodash';

import classnames from 'classnames/bind';
import styles from './Label.scss';
const cx = classnames.bind(styles);

const Label = ({label, require}) => {
    return (
        <div>
            {label&&
                <div className={cx('input-label')}>
                    {_.capitalize(label)}{require&&<div className={cx('form-require-circle')}/>}
                </div>
            }
        </div>
    );
};

export default Label;