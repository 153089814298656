import { createAction, handleActions } from 'redux-actions';

const initialState = {
    confirmation : {
        isOpen: false
    },
    activeModal: {
        isOpen: false,
        name: ""
    }
}

const OPEN_CONFIRMATION = 'modal/OPEN_CONFIRMATION';
const CLOSE_CONFIRMATION = 'modal/CLOSE_CONFIRMATION';
const OPEN_MODAL = 'modal/OPEN_MODAL';
const CLOSE_MODAL = 'modal/CLOSE_MODAL';

export const openConfirmation = createAction(OPEN_CONFIRMATION);
export const closeConfirmation = createAction(CLOSE_CONFIRMATION);
export const openModal = createAction(OPEN_MODAL);
export const closeModal = createAction(CLOSE_MODAL);


export default handleActions({
    [OPEN_CONFIRMATION]: (state, action) => {
        return {
            ...state,
            confirmation : {
                isOpen: true
            }
        }
    },

    [CLOSE_CONFIRMATION]: (state, action) => {
        return {
            ...state,
            confirmation : {
                isOpen: false
            }
        }
    },

    [OPEN_MODAL]: (state, action) => {
        return {
            ...state,
            activeModal : {
                isOpen: true,
                name: action.payload
            }
        }
    },

    [CLOSE_MODAL]: (state, action) => {
        return {
            ...state,
            activeModal : {
                isOpen: false,
                name: ""
            }
        }
    },

}, initialState);