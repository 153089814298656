import _ from 'lodash';
import countryCodeList from './countryCodeList';

export const convertCountryCodeToName = (code: string) => {
    const index = _.findIndex(countryCodeList, (item) => {
        return item.code === code
    })
    if(index === -1) {
        return `NO_COUNTRY_CODE(country code: ${code})`;
    } else {
        return countryCodeList[index].name;
    }
}
const nameGenerator = {
    generateAddressText: (
        countryCode:string,
        state:string,
        city:string,
        street:string,
        zip:string
    ) => {
        if(!countryCode || !city || !street) {
            return 'Address data is wrong.';
        }
        return `${street}, ${city}${state&&`, ${state}`}, ${convertCountryCodeToName(countryCode)}${zip&&`(${zip})`}`;
    },

    generateShortSampleName: (
        sampleId: string|number,
        coffeeOriginCode: string,
        coffeeRegion: string,
        coffeeProcessing: string
    ) => {
        if(!(sampleId&&coffeeOriginCode&&coffeeRegion&&coffeeProcessing)) {
            return 'Sample data is wrong.'
        }
        return `(sp: ${sampleId})${convertCountryCodeToName(coffeeOriginCode)}-${coffeeRegion}-${coffeeProcessing}`;
    }
}
export default nameGenerator;