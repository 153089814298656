import React from 'react'
import styled, { css } from 'styled-components';
import { rem, utils, flex, palette, media } from 'react-c2c-ui';
import { Radar } from 'react-chartjs-2';

interface OwnProps {
    acidity?: string; afterTaste?: string; balance?: string;
    body?: string; flavor?: string; fragrance?: string; overall?: string;
}
type Props = OwnProps;
export const radarOption = {
    maintainAspectRatio: false,
    legend: {
        display: false,
    },
    scale: {
        ticks: {
            beginAtZero: true,
            max: 10,
            min: 5,
            display: true,
            stepSize: 1
        }
    },
    layout: {
        padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
        }
    },
    tooltips: {
        enabled: true,
        callbacks: {
            label: function (tooltipItem: any, data: any) {
                return data.labels[tooltipItem.index] + ' : ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            }
        }
    }
}
const CuppingChart: React.SFC<Props> = ({
    acidity, afterTaste, balance,
    body, flavor, fragrance, overall
}) => {

    const radarData = {
        labels: [
            `Fragrance/Aroma`,
            'Flavor',
            'Aftertaste',
            'Acidity',
            'Body',
            'Balance',
            'Overall'
        ],
        datasets: [
            {
                backgroundColor: 'rgba(0, 114, 206,0)',
                borderColor: "rgba(0, 114, 206,1)",
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(179,181,198,1)',
                data: [fragrance, flavor, afterTaste, acidity, body, balance, overall]
            },
        ]

    };

    return (
        <CuppingChartBlock>
            <Radar data={radarData}
                options={{
                    ...radarOption
                }}
            />
        </CuppingChartBlock>
    )
}

export const CuppingChartBlock = styled.div`
    margin-left: ${rem(20)};
    max-width: 100mm;
    height: 55mm;
    ${flex.flex('none')};
    @media ${media.LARGE} {
        margin: 40px auto;
    }
`;

export default CuppingChart;