import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components';
import { rem, utils, flex, palette, media, Button, ButtonGroup } from 'react-c2c-ui';
import { RouteComponentProps, withRouter } from 'react-router';
import useInputs from 'lib/hooks/useInputs';
import HeaderTemplate from 'components/_templates/HeaderTemplate';
import { links } from 'lib/links';
import CoffeeSummary from 'components/_templates/CoffeeSummary';
import GGForm from './GGForm';
import useRequest from 'lib/hooks/useRequest';
import cAxios from 'lib/axiosInstance';

interface OwnProps {

}
type Props = OwnProps&RouteComponentProps<{samplePackId:string, mode:string}>;

const EvaluateGreenGrading: React.SFC<Props> = ({history, match}) => {
    const [forms, onChange, onSelect, dispatchData] = useInputs({
        grader: "",
        gradingDate: new Date().toJSON().slice(0,10),

        density:700,
        waterActivity:0.500,
        moisture:10.1,

        ggWeight: 350,
        defectsWeight: 0,
        quantityForRoasting: 50,

        fullBlack:0,
        fullSour:0,
        driedCherryAndPod:0,
        fungusDamaged:0,
        severeInsectDamaged:0,
        foreignMatter:0,
        partialBlack:0,
        partialSour:0,
        parchment:0,
        floater:0,
        immatureAndUnripe:0,
        withered:0,
        shell:0,
        brokenAndChippedAndCut:0,
        hullAndHusk:0,
        slightInsectDamaged:0,
    })

    const [mode, setMode] = useState<'WRITE'|'EDIT'>('WRITE')

    //# GET_SAMPLE_PACK
    const [
        onRequestGetSamplePack,
        getSamplePackResult,
        getSamplePackLoading,
        getSamplePackError
    ] = useRequest<any>(()=>
        cAxios.get(
            `/sample/mgmt/samplePacks/${match.params.samplePackId}`
        )
    );
    const getSamplePack = async () => {
        const data = await onRequestGetSamplePack();
        console.log('data', data);
        const greenGrading = data.samplePackOne.greenGrading;
        if(greenGrading) {
            dispatchData({
                grader: greenGrading.grader,
                gradingDate: greenGrading.gradingDate
                    ?greenGrading.gradingDate.slice(0,10)
                    :new Date().toJSON().slice(0,10),
                density: greenGrading.density,
                waterActivity: greenGrading.waterActivity,
                moisture: greenGrading.moisture,
                defectsWeight: greenGrading.defectsWeight,
                ggWeight: greenGrading.measuredQuantity?greenGrading.measuredQuantity:forms.ggWeight,
                quantityForRoasting: data.samplePackOne.roastedSampleWeight,

                fullBlack: greenGrading.fullBlack,
                fullSour: greenGrading.fullSour,
                driedCherryAndPod: greenGrading.driedCherryAndPod,
                fungusDamaged: greenGrading.fungusDamaged,
                severeInsectDamaged: greenGrading.severeInsectDamaged,
                foreignMatter: greenGrading.foreignMatter,
                partialBlack: greenGrading.partialBlack,
                partialSour: greenGrading.partialSour,
                parchment: greenGrading.parchment,
                floater: greenGrading.floater,
                immatureAndUnripe: greenGrading.immatureAndUnripe,
                withered: greenGrading.withered,
                shell: greenGrading.shell,
                brokenAndChippedAndCut: greenGrading.brokenAndChippedAndCut,
                hullAndHusk: greenGrading.hullAndHusk,
                slightInsectDamaged: greenGrading.slightInsectDamaged,
            })
        } else {
            if(mode==='EDIT') {
                console.error('DATA_LOAD_ERROR: It\'s "edit mode". But no data.');
            }
        }
    }

    useEffect(() => {
        if(match.params.mode === 'edit') {
            setMode('EDIT')
        }
        getSamplePack()
    }, [])
    
    

    const [
        onRequestPostGg,
        postGgResult,
        postGgLoading,
        postGgError
    ] = useRequest<any>((data)=>
        cAxios.post(
            '/coffee/mgmt/evaluate/green-grading',
            data
        )
    );
    const submit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = {
            samplePackId: Number(match.params.samplePackId),

            grader: forms.grader,
            gradingDate: forms.gradingDate,

            density:forms.density,
            waterActivity:forms.waterActivity,
            moisture:forms.moisture,

            defectsWeight:forms.defectsWeight,
            measuredQuantity: forms.ggWeight,
            quantityForRoasting: forms.quantityForRoasting,

            fullBlack: forms.fullBlack,
            fullSour: forms.fullSour,
            driedCherryAndPod: forms.driedCherryAndPod,
            fungusDamaged: forms.fungusDamaged,
            severeInsectDamaged: forms.severeInsectDamaged,
            foreignMatter: forms.foreignMatter,
            partialBlack: forms.partialBlack,
            partialSour: forms.partialSour,
            parchment: forms.parchment,
            floater: forms.floater,
            immatureAndUnripe: forms.immatureAndUnripe,
            withered: forms.withered,
            shell: forms.shell,
            brokenAndChippedAndCut: forms.brokenAndChippedAndCut,
            hullAndHusk: forms.hullAndHusk,
            slightInsectDamaged: forms.slightInsectDamaged
        }
        await onRequestPostGg(data);
        history.push(links.samplePackDetail.replace(':samplePackId', match.params.samplePackId))
    }
    if(getSamplePackResult) {
        const coffee = getSamplePackResult.samplePackOne.coffee;
        const farm = coffee.farm;
        return (
            <EvaluateGreenGradingBlock onSubmit={submit}>
                    <HeaderTemplate
                        backText="samplePack detail 페이지로 되돌아갑니다."
                        backTo={links.samplePackDetail.replace(":samplePackId", match.params.samplePackId)}
                        headerText="Green Grading 정보 입력"
                    />
                    <CoffeeSummary
                        coffeeId={coffee.id}
                        country={farm.country}
                        grade={coffee.grade}
                        price={coffee.price}
                        processing={coffee.processing}
                        region={farm.region}
                        varieties={coffee.varieties.map((item:any) => item.variety)}
                    />
                    <GGForm
                        forms={forms}
                        handleChange={onChange}
                    />
    
                    <ButtonGroup rightAlign>
                        <Button
                            onClick={()=>{history.replace(links.samplePackDetail.replace(":samplePackId", match.params.samplePackId))}}
                            type="button"
                            theme="secondary">cancel</Button>
                        <Button type="submit">submit</Button>
                    </ButtonGroup>
            </EvaluateGreenGradingBlock>
        )
    } else { return (<h3>hello</h3>) }
}

const EvaluateGreenGradingBlock = styled.form`
    ${utils.containerBox};
    max-width: ${rem(1080)};
    margin: auto;
    margin-top: ${rem(20)};
    padding-bottom: ${rem(80)};
`;

export default withRouter(EvaluateGreenGrading);