import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components';
import { rem, utils, flex, palette, media, Textarea } from 'react-c2c-ui';
import { ButtonGroup, Button, Input, Modal } from 'react-c2c-ui';
import useInputs from 'lib/hooks/useInputs';
import HeaderTemplate from 'components/_templates/HeaderTemplate';
import { links } from 'lib/links';
import { withRouter, RouteComponentProps } from 'react-router';
import CoffeeSummary from 'components/_templates/CoffeeSummary';
import useRequest from 'lib/hooks/useRequest';
import Switch from "react-switch";
import CuppingInfo, { TableBox } from './samplePack/CuppingInfo';
import GreenGradingInfo from './samplePack/GreenGradingInfo';
import RemainingWeightDetail from './samplePack/RemainingWeightDetail';
import SamplePackSampleList from './samplePack/SamplePackSampleList';
import cAxios from 'lib/axiosInstance';

interface OwnProps {

}
type Props = OwnProps & RouteComponentProps<{ samplePackId: string }>;
interface SamplePackDetailTypes {
    id: number;
    coffeeId: number;
    greenGradingId: number;
    courier: string;
    trackingNumber: string;
    receiveSampleDate: Date;
    sampleWeight: number;
    roastedSampleWeight: null;
    remainingSampleWeight: null;
    status: "SENDING" | "RECEIVED" | "EVALUATING" | "COMPLATE" | "EXPIRED";
    inValidTrackingNumber: false;
    createdAt: Date;
    updatedAt: Date;
    samples: Array<any>;
    cuppings: Array<any>;
    greenGrading: any;
    flavors: Array<any>;
    coffee: any;
}
const SamplePackDetail: React.SFC<Props> = ({ history, match }) => {
    const samplePackId = match.params.samplePackId;

    //# GET_SAMPLE_PACK_DETAIL
    const [currentRemainingWeight, setCurrentRemainingWeight] = useState(0);
    const [samplesTotalWeight, setSamplesTotalWeight] = useState(0);
    const [
        onRequestGetSamplePackDetail,
        getSamplePackDetailResult,
        getSamplePackDetailLoading,
        getSamplePackDetailError
    ] = useRequest<any>(() =>
        cAxios.get(`/sample/mgmt/samplePacks/${match.params.samplePackId}`)
    );

    const getSamplePackDetail = async () => {
        const data = await onRequestGetSamplePackDetail();
        console.log('data', data);
        const { sampleWeight, roastedSampleWeight, greenGrading, samples } = data.samplePackOne;
        let sampleTotalWeight = 0;
        samples.map((item: any) => { sampleTotalWeight += Number(item.amount) });
        const defectsWeight = greenGrading ? greenGrading.defectsWeight : 0;
        const remaining = sampleWeight - (roastedSampleWeight + defectsWeight + sampleTotalWeight);
        console.log('remaining', remaining);
        console.log('sampleTotalWeight', sampleTotalWeight);
        setCurrentRemainingWeight(remaining);
        setSamplesTotalWeight(sampleTotalWeight);

    }

    useEffect(() => {
        getSamplePackDetail()
    }, [])

    //# RECEIVE_SAMPLEPACK
    const [receivedSamplePackForms, onChangeReceivingSampleForm, onSelect, dispatchData] = useInputs<{ sampleWeight: number | undefined, receiveSampleDate: String | undefined }>({
        sampleWeight: undefined,
        receiveSampleDate: undefined
    })

    const [receivingSampleModal, setReceivingSampleModal] = useState({
        samplePackId: -1,
        open: false
    })

    const openPutReceivingSampleModal = (samplePackId: number, sampleWeight: number, receiveSampleDate: string) => {
        setReceivingSampleModal({
            samplePackId,
            open: true
        })
        dispatchData({
            sampleWeight, receiveSampleDate
        })
    }

    const closeReceivingSampleModal = () => {
        setReceivingSampleModal({
            samplePackId: -1,
            open: false
        })
        dispatchData({
            sampleWeight: undefined, receiveSampleDate: undefined
        })
    }

    const [
        onRequestReceiveSample,
        receiveSampleResult,
        receiveSampleLoading,
        receiveSampleError
    ] = useRequest<any>((data: any, samplePackId: number) =>
        cAxios.post(
            `/sample/mgmt/samplePacks/receive/${samplePackId}`,
            data
        )
    );
    const confirmReceivingSampleModal = async () => {
        const samplePackId = receivingSampleModal.samplePackId;
        const data = {
            sampleWeight: receivedSamplePackForms.sampleWeight,
            receiveSampleDate: receivedSamplePackForms.receiveSampleDate
        }
        await onRequestReceiveSample(data, samplePackId);
        await getSamplePackDetail();
        closeReceivingSampleModal();
    }


    //# REGISTER_SAMPLES
    const [registerSampleModal, setRegisterSampleModal] = useState({
        open: false,
        samplePackId: -1,
        sampleWeight: '0',
        receiveSampleDate: ""
    });
    const [
        registerSampleForm,
        onChangeRegisterSampleForm,
        onSelectRegisterSampleForm,
        dispatchRegisterSampleForm
    ] = useInputs({
        oneSampleWeight: '0',
        oneSamplePrice: '0',
        purpose: '',
        note: ''
    })


    const openRegisterSampleModal = (samplePackId: number, sampleWeight: string, receiveSampleDate: string) => {
        setRegisterSampleModal({
            open: true,
            samplePackId,
            sampleWeight,
            receiveSampleDate
        });
    }
    const cancelRegisterSampleModal = () => {
        dispatchRegisterSampleForm({
            oneSampleWeight: '0',
            oneSamplePrice: '0',
            purpose: '',
            note: ''
        })
        setRegisterSampleModal({
            open: false,
            samplePackId: -1,
            sampleWeight: '0',
            receiveSampleDate: ""
        });
    }
    const confirmRegisterSampleModal = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const samplePackId = registerSampleModal.samplePackId;
        const res = await cAxios.post(
            `/sample/mgmt/samplePacks/samples/${samplePackId}`,
            {
                oneSampleWeight: registerSampleForm.oneSampleWeight,
                oneSamplePrice: registerSampleForm.oneSamplePrice,
                purpose: registerSampleForm.purpose,
                note: registerSampleForm.note
            }
        )

        await getSamplePackDetail();
        cancelRegisterSampleModal();
    }
    const [switchChecked, setSwitchChecked] = useState(false);
    const handleSwichChange = () => {
        setSwitchChecked(!switchChecked);
        if (!switchChecked) {
            onSelectRegisterSampleForm('purpose', 'FOR_SALE')
            onSelectRegisterSampleForm('oneSamplePrice', '0')
        } else {
            onSelectRegisterSampleForm('purpose', '')
            onSelectRegisterSampleForm('oneSamplePrice', '0')
        }
    }

    //# CHANGE SAMPLE PACK POINTER
    const [changePointerModalOpen, setChangePointerModalOpen] = useState(false);
    const openChangePointerModal = () => {
        setChangePointerModalOpen(true);
    }
    const closeChangePointerModal = () => {
        setChangePointerModalOpen(false);
    }

    const [
        onRequestPutSamplePackPointer,
        putSamplePackPointerResult,
        putSamplePackPointerLoading,
        putSamplePackPointerError
    ] = useRequest<any>(()=>
        cAxios.put(
            `/sample/mgmt/samplePacks/${samplePackId}/pointer`
        )
    );
    const changePointer = async () => {
        await onRequestPutSamplePackPointer();
        await getSamplePackDetail();
        closeChangePointerModal();
    }

    //# RENDER
    if (getSamplePackDetailResult) {
        const {
            id,
            coffeeId,
            greenGradingId,
            courier,
            trackingNumber,
            receiveSampleDate,
            sampleWeight,
            roastedSampleWeight,
            status,
            inValidTrackingNumber,
            createdAt,
            updatedAt,
            samples,
            cuppings,
            greenGrading,
            flavors,
            coffee={}
        } = getSamplePackDetailResult.samplePackOne;

        const {
            selectedSamplePackId,
            deprecated,
            processing,
            grade,
            harvest,
            screenSize,
            packaging,
            bagSize,
            price,
            moq,
            stock,
            varieties,
            farm,
            user
        } = coffee;
        console.log('samplePackId', samplePackId);
        console.log('selectedSamplePackId', selectedSamplePackId);
        return (
            <SamplePackDetailBlock>
                <HeaderTemplate
                    backText="이 샘플팩의 Coffee Detail 페이지로 돌아갑니다."
                    backTo={links.coffeeDetail.replace(':id', String(coffeeId))}
                    headerText={`SAMPLE PACK ID: ${samplePackId}`}
                />

                <CoffeeSummary
                    coffeeId={coffeeId}
                    country={farm.country}
                    grade={coffee.grade}
                    price={coffee.price}
                    processing={coffee.processing}
                    region={farm.region}
                    varieties={varieties.map((item: any) => item.variety)}
                />

                <Highlight isCompleted = {status === 'COMPLETE'}>
                    { status === 'RECEIVED' &&
                        <h3>샘플 수령 완료</h3>
                    }
                    { status === 'COMPLETE' &&
                        <React.Fragment>
                            {Number(samplePackId) === Number(selectedSamplePackId)
                                ?
                                <h3>품질 평가 완료 <span>ID:{coffeeId} 커피가 이 샘플팩의 정보를 사용하고 있습니다.</span></h3>
                                
                                :
                                <React.Fragment>
                                    <h3>품질 평가 완료 <span>정보를 사용하고 있지 않음.</span></h3>
                                    <p onClick={openChangePointerModal}>이 샘플팩의 정보가 표시될 수 있게 변경</p>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    }
                </Highlight>

                <section>
                    <div className="row">
                        <h2>샘플팩 정보</h2>
                        <div>
                            <p>Received Date: {String(receiveSampleDate).substring(0, 10)}</p>
                            <p>Received Sample Weight: {sampleWeight}g</p>
                            <p>Remaining Sample Weight: {currentRemainingWeight}g</p>
                            <RemainingWeightDetail style={{ border: 'none', margin: 0, padding: 0, paddingLeft: '20px' }}
                                receivedWeight={sampleWeight}
                                roastroastedWeighted={roastedSampleWeight}
                                defectsWeight={greenGrading ? greenGrading.defectsWeight : 0}
                                registeredSampleQuantity={samples.length}
                                registeredSampleTotalWeight={samplesTotalWeight}
                            />
                        </div>
                    </div>
                    <div className="edit-btn" onClick={() => { openPutReceivingSampleModal(Number(samplePackId), sampleWeight, String(receiveSampleDate.substring(0, 10))) }}>수정</div>
                </section>

                <section>
                    {greenGrading
                        ?
                        <div className="col">
                            <h2>Green grading 정보</h2>
                            <GreenGradingInfo greenGrading={greenGrading} />
                            <p className="edit-btn" onClick={() => { history.push(links.evaluateGreenGrading.replace(':samplePackId', samplePackId).replace(':mode', 'edit')) }}>수정</p>
                        </div>

                        :
                        <div className="row">
                            <h2>Green grading 정보</h2>
                            <div>
                                <p>Green grading 정보가 존재하지 않습니다. ‘green grading 정보 입력’ 버튼을 통해 정보를 입력해주세요</p>
                                <Button size="small" onClick={() => { history.push(links.evaluateGreenGrading.replace(':samplePackId', samplePackId).replace(':mode', 'write')) }}>
                                    green grading 정보 입력
                                </Button>
                            </div>
                        </div>
                    }
                    
                </section>

                <section>
                    {cuppings.length
                        ?
                        <div className="col">
                            <h2>Cupping 정보</h2>
                            <CuppingInfo cuppings={cuppings} flavors={flavors} />
                        </div>

                        :
                        <div className="row">
                            <h2>Cupping 정보</h2>
                            <div>
                                <p>Cupping 정보가 존재하지 않습니다. ‘Cupping 정보 입력’ 버튼을 통해 정보를 입력해주세요</p>
                                <Button size="small" onClick={() => { history.push(links.evaluateCupping.replace(':samplePackId', samplePackId).replace(':mode', 'write')) }}>
                                    Cupping 정보 입력
                                </Button>
                            </div>
                        </div>
                    }
                    {/* //TEMP*/}
                    {/* <p className="edit-btn" onClick={()=>{history.push(links.evaluateCupping.replace(':samplePackId', samplePackId).replace(':mode', 'edit'))}}>수정</p> */}
                </section>

                <section>
                    {samples.length
                        ?
                        <div className="col">
                            <h2>샘플 정보</h2>
                            <SamplePackSampleList samples={samples} />
                            <ButtonGroup rightAlign style={{ justifyContent: 'center' }}>
                                <Button onClick={() => { openRegisterSampleModal(Number(samplePackId), sampleWeight, String(receiveSampleDate).substring(0, 10)); }}>
                                    샘플 추가
                                </Button>
                            </ButtonGroup>

                        </div>

                        :

                        <div className="row">
                            <h2>샘플 정보</h2>
                            <div>
                                <p>샘플을 제공하기 위해 샘플 등록을 해주세요.</p>
                                <Button size="small" onClick={() => { openRegisterSampleModal(Number(samplePackId), sampleWeight, String(receiveSampleDate).substring(0, 10)); }}>
                                    샘플 등록
                            </Button>
                            </div>
                        </div>
                    }
                </section>


                {/* //# MODAL */}
                <Modal hideButtons visible={registerSampleModal.open} onCancel={cancelRegisterSampleModal}>
                    <AddSampleModalContents onSubmit={confirmRegisterSampleModal}>
                        <h5>현재 남은 샘플 무게: <span>{currentRemainingWeight}g</span></h5>
                        <RemainingWeightDetail
                            receivedWeight={registerSampleModal.sampleWeight}
                            roastroastedWeighted={roastedSampleWeight}
                            defectsWeight={greenGrading ? greenGrading.defectsWeight : 0}
                            registeredSampleQuantity={samples.length}
                            registeredSampleTotalWeight={samplesTotalWeight}
                        />
                        <p>Received Date: {registerSampleModal.receiveSampleDate}</p>

                        <div className="inputs-wrapper">
                            <Input
                                label={`샘플 개당 무게 (max: ${currentRemainingWeight})`}
                                name="oneSampleWeight"
                                required
                                symbol="gram"
                                type="number"
                                value={registerSampleForm.oneSampleWeight}
                                onChange={onChangeRegisterSampleForm}
                                min={50}
                                max={currentRemainingWeight}
                            />

                            <div className="switch-box">
                                <div>
                                    <p>샘플 판매 여부</p>
                                    <Switch
                                        onChange={handleSwichChange}
                                        checked={switchChecked}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        height={20}
                                        width={40}
                                    />
                                </div>
                                <p>샘플을 <span>{switchChecked ? "판매" : "제공"}</span>할 목적으로 사용됩니다.</p>
                            </div>

                            <Input
                                label="샘플 개당 가격"
                                name="oneSamplePrice"
                                required
                                symbol="USD"
                                value={registerSampleForm.oneSamplePrice}
                                onChange={onChangeRegisterSampleForm}
                                disabled={!switchChecked}
                            />
                            <Input
                                label="샘플 사용처"
                                name="purpose"
                                required
                                value={registerSampleForm.purpose}
                                onChange={onChangeRegisterSampleForm}
                                disabled={switchChecked}
                            />

                            <Textarea
                                label="샘플 메모"
                                name="note"
                                value={registerSampleForm.note}
                                onChange={onChangeRegisterSampleForm}
                            />
                        </div>
                        <ButtonGroup rightAlign style={{ marginTop: '3rem' }}>
                            <Button type="button" theme="tertiary" onClick={cancelRegisterSampleModal}>Cancel</Button>
                            <Button type="submit">Submit</Button>
                        </ButtonGroup>
                    </AddSampleModalContents>
                </Modal>

                <Modal
                    cancellable
                    visible={receivingSampleModal.open}
                    onCancel={closeReceivingSampleModal}
                    onConfirm={confirmReceivingSampleModal}
                >
                    <div className="receive_sample-box">
                        <h3>샘플 수령 정보 입력</h3>
                        <Input
                            label="샘플 수령 날짜"
                            name="receiveSampleDate"
                            required
                            type="date"
                            value={String(receivedSamplePackForms.receiveSampleDate)}
                            onChange={onChangeReceivingSampleForm}
                        />

                        <Input
                            label="샘플 무게"
                            required
                            symbol="gram"
                            type="number"
                            name="sampleWeight"
                            value={Number(receivedSamplePackForms.sampleWeight)}
                            onChange={onChangeReceivingSampleForm}
                        />
                    </div>
                </Modal>

                <Modal
                    cancellable
                    visible={changePointerModalOpen}
                    onCancel={closeChangePointerModal}
                    onConfirm={changePointer}
                >
                    <h3>현재의 샘플팩으로 ID:{coffeeId} 커피의 포인터를 변경 하시겠습니까?</h3>
                    <p>기존의 샘플팩(ID:{selectedSamplePackId}) 대신 현재의 샘플신(ID:{samplePackId})에 해당되는 품질평가 정보가 표시됩니다.</p>
                </Modal>
            </SamplePackDetailBlock>
        )
    } else { return null }
}
export const infoBoxCss = css`
    position: relative;
    >.row {
        ${flex.row};
        >h2 {
            width: ${rem(250)};
            margin-right: ${rem(20)};
            font-size: ${rem(24)};
        }
    }

    >.col {
        ${flex.col};
        position: relative;
        >h2 {
            width: ${rem(250)};
            margin-right: ${rem(20)};
            font-size: ${rem(24)};
            margin-bottom: ${rem(20)};
        }
    }

    .edit-btn {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        color: #219653;
        text-decoration: underline;
        font-size: ${rem(16)}
    }
`;
const SamplePackDetailBlock = styled.div`
    ${utils.containerBox};
    max-width: ${rem(1080)};

    >section {
        ${infoBoxCss};
        >.row {
            ${flex.row};
            >div {
                >p {
                    font-size: ${rem(14)};
                    margin-bottom: ${rem(10)};
                }
            }
        }

        >.col {
            
            ${flex.col};
            >h2 {
                font-size: ${rem(24)};
                margin-bottom: ${rem(20)};
            }
            >div {
                
            }
        }
    }
`;

const Highlight = styled.div<{isCompleted: boolean}>`
    background: ${props => props.isCompleted ? '#6fcf97' : palette.blue100};
    padding: ${rem(10)};
    margin-bottom: ${rem(40)};
    ${flex.row};
    ${flex.jc.spaceB};
    >h3 {
        font-size: ${rem(18)};
        >span {
            margin-left: ${rem(10)};
            color: white;
        }
    }
    >p {
        color: white;
        cursor: pointer;
        text-decoration: underline;
    }
    
`;

const AddSampleModalContents = styled.form`
    >h5 {
        font-size: ${rem(16)};
        >span {
            color: ${palette.blue500};
        }
    }
    
    >p {
        text-align: right;
        font-size: ${rem(12)};
    }
    >.inputs-wrapper {
        >div + div {
            margin-top: ${rem(20)};
        }

        >.switch-box {
            >:first-child {
                ${flex.row};
                margin-bottom: ${rem(5)};
                >p {
                    margin-right: ${rem(20)};
                    font-weight: 700;
                    font-size: ${rem(14)};
                    color: ${palette.gray800};
                }
            }

            >p {
                font-size: ${rem(12)};
                color: ${palette.gray500};
                >span {
                    color: green;
                    font-weight: 700;
                }
            }
        }
    }
`;

export default withRouter(SamplePackDetail);