import React from 'react'
import styled, { css } from 'styled-components';
import { rem, utils, flex, palette, media } from 'react-c2c-ui';
import { MdLocationOn, MdSpa, MdTimeline } from 'react-icons/md';

interface OwnProps {
    coffeeId: string|number,
    country: string,
    region: string,
    processing: string,
    grade: string,
    varieties: string[],
    price: string
}
type Props = OwnProps;

const CoffeeSummary: React.SFC<Props> = ({coffeeId, country, region, processing, grade, varieties, price}) => {
    return (
        <CoffeeSummaryBlock>
            <h3>Coffee ID: {coffeeId}</h3>
            <div>
                <MdLocationOn/>
                <p>{country} - {region}</p>
            </div>
            <div>
                <MdSpa/>
                <p>{processing} / {grade} / {varieties.join(',')}</p>
            </div>
            <div>
                <MdTimeline/>
                <p>${price}/lb</p>
            </div>
        </CoffeeSummaryBlock>
    )
}

const CoffeeSummaryBlock = styled.div`
    margin: ${rem(40)} 0;
    >h3 {
        font-size: ${rem(18)};
        ${palette.blue700};
    }

    >div {
        ${flex.row};
        ${flex.ai.center};
        margin-top: ${rem(10)};

        >svg {
            font-size: ${rem(24)};
        }

        >p {
            margin-left: ${rem(10)};
        }
    }
`;

export default CoffeeSummary;