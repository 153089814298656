import { createAction, handleActions} from 'redux-actions';

const initialState = {
    pagination : {
        name: '',
        page: 1,
    },
    coffee_status : "ON_SALE",

    myProductTab: "DE",
    loadingActive: false
}

const SET_PAGE_NUM = 'SET_PAGE_NUM';
const INIT_PAGE_NUM = "INIT_PAGE_NUM";
const SET_COFFEE_STATUS = "SET_COFFEE_STATUS";
const INIT_COFFEE_STATUS = "INIT_COFFEE_STATUS";

export const setPageNum = createAction(SET_PAGE_NUM);
export const initPageNum = createAction(INIT_PAGE_NUM);
export const setCoffeeStatus = createAction(SET_COFFEE_STATUS);
export const initCoffeeStatus = createAction(INIT_COFFEE_STATUS);

const MY_PRODUCT_TAB = 'MY_PRODUCT_TAB';
export const setMyProductTab = createAction(MY_PRODUCT_TAB);
const myProductTabAction = (state, action) => {
    return {
        ...state,
        myProductTab: action.payload
    }
}

const SET_LOADING = 'SET_LOADING';
export const setLoading = createAction(SET_LOADING);
const setLoadingAction = (state, action) => {
    let active = false;
    if(Array.isArray(action.payload)) {
        active = action.payload.some(function (item, index) {
            return item===true;
        });
    } else if(typeof action.payload === "boolean"){
        active = action.payload
    } else {
        console.log('ERR: payload type error / action.payload : ', action.payload);
        return {
            ...state
        }
    }
    return {
        ...state,
        loadingActive: active
    }
}

/*
    handleActions
*/
export default handleActions({
    [SET_PAGE_NUM] : (state, action) => {
        return Object.assign({}, state, {
            pagination : action.payload
        })
    },
    [INIT_PAGE_NUM] : (state, action) => {
        return Object.assign({}, state, {
            pagination : initialState.pagination
        })
    },

    [SET_COFFEE_STATUS] : (state, action) => {
        return Object.assign({}, state, {
            coffee_status : action.payload
        })
    },
    [INIT_COFFEE_STATUS] : (state, action) => {
        return Object.assign({}, state, {
            coffee_status : initialState.coffee_status
        })
    },

    [MY_PRODUCT_TAB] : myProductTabAction,
    [SET_LOADING] : setLoadingAction,

}, initialState);
