import React, { Component, useState, useEffect, SyntheticEvent } from 'react';
import _ from 'lodash';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import CuppingForm from './CuppingForm';
import Axios from 'axios';
import { mkCuppingData, mkC2CScore } from 'lib/scoreMaker';
import useRequest from 'lib/hooks/useRequest';
import { links } from 'lib/links';
import cAxios from 'lib/axiosInstance';
interface OwnProps {

}
type Props = OwnProps&RouteComponentProps<{samplePackId:string, mode:string}>;

const EvaluateCupping: React.SFC<Props> = ({history, match}) => {
    const samplePackId = match.params.samplePackId;
    const [mode, setMode] = useState<'WRITE'|'EDIT'>('WRITE');

    useEffect(() => {
        if(match.params.mode === 'edit') {
            setMode('EDIT')
        }
    }, [])
    
    const [rowArray, setrowArray] = useState([
        {
            name:"AVERAGE",
            fragrance:9.5,
            flavor:9.5,
            afterTaste:9.5,
            acidity:9.5,
            body:9.5,
            uniformity:10,
            sweetness:10,
            cleanCup:10,
            balance:9.5,
            overall:10
        }
    ])

    const addRow = () => {
        setrowArray([
            ...rowArray,
            {
                name:"SEO",
                fragrance:9.5,
                flavor:9.5,
                afterTaste:9.5,
                acidity:9.5,
                body:9.5,
                uniformity:10,
                sweetness:10,
                cleanCup:10,
                balance:9.5,
                overall:10
            }
        ]);
    }
    const [cuppingScore, setCuppingScore] = useState(mkCuppingData(rowArray).cuppingScore);
    const [averages, setAverages] = useState(mkCuppingData(rowArray).averageItems);
    const handleChangeRow = (e:React.ChangeEvent<HTMLInputElement>, index:number) => {
        const name = e.target.name;
        const value = e.target.value;

        let newRowArray = [
            ...rowArray
        ];

        newRowArray[index] = {
            ...newRowArray[index],
            [name]: value
        }

        setrowArray(newRowArray);
        setCuppingScore(mkCuppingData(newRowArray).cuppingScore);
        setAverages(mkCuppingData(newRowArray).averageItems);

    }

    const removeRow = (index:number) => {
        const newRowArray = [
            ...rowArray
        ]
        if(index>-1) {
            if(newRowArray.length>1) {
                newRowArray.splice(index,1);
            } else {
                console.error(`at least 1 row`);
            }
        }
        setrowArray(newRowArray);
    }

    //# FLAVOR NOTE
    const [notes, setNotes] = useState("");
    const [noteList, setNoteList] = useState<string[]>([]);
    const inputNotes = (value:string) => {
        setNotes(value);
        const formatedNoteList = value.split(',').map((item: string) => {
            return _.trim(item);
        }).map((item:string) => {
            return _.capitalize(item);
        })

        setNoteList(formatedNoteList);
    }

    //# CUPPING DATE
    const [cuppingDate, setCuppingDate] = useState(new Date().toJSON().slice(0,10))
    const handleChangeDate = (e:React.ChangeEvent<HTMLInputElement>) => {
        setCuppingDate(e.target.value);
    }

    //# SUBMIT CUPPING
    const [
        onRequestPostCupping,
        postCuppingResult,
        postCuppingLoading,
        postCuppingError
    ] = useRequest<any>((data)=>
        cAxios.post(
            `/sample/mgmt/samplePacks/${samplePackId}/evaluate/cupping`,
            data
        )
    );
    const goToSamplePackDetail = () => {
        history.push(
            links.samplePackDetail.replace(
                ':samplePackId', samplePackId
            )
        )
    }
    const submit = async (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data = {
            cuppingList:rowArray,
            noteList,
            cuppingDate
        }
        await onRequestPostCupping(data);
        goToSamplePackDetail();
    }
    return (
        <CuppingForm
            rowArray={rowArray}
            addRow = {addRow}
            handleChangeRow = {handleChangeRow}
            removeRow = {removeRow}
            notes={notes}
            noteList={noteList}
            inputNotes = {inputNotes}
            cancel={goToSamplePackDetail}
            submit={submit}
            averages={averages}
            cuppingScore={cuppingScore}
            postCuppingError={postCuppingError}
            cuppingDate={cuppingDate}
            handleChangeDate={handleChangeDate}
        />
    )
}
export default withRouter(EvaluateCupping);