import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Route } from 'react-router-dom';
import styles from './App.module.scss';
import classnames from 'classnames/bind';

// containers
import Nav from './containers/Nav';
import SignIn from './containers/SignIn';
import { links } from './lib/links';
import CoffeeDetail from './containers/CoffeeDetail/CoffeeDetail.tsx';
import EvaluateCupping from './containers/EvaluateCupping/EvaluateCupping';
import EvaluateGreenGrading from './containers/EvaluateGreenGrading';
import AddSamples from './containers/sample/AddSamples';
import SamplePackDetail from 'containers/SamplePackDetail';
import CoffeeListPage from 'containers/CoffeeListPage';
import { messaging } from 'lib/firebase';
import AdminSettingPage from 'containers/AdminSettingPage';
import EvaluationReport from 'containers/EvaluationReport/EvaluationReport';
import SampleTradeList from 'containers/sample/SampleTradeList/SampleTradeList';
import SampleTradeDetail from 'containers/sample/SampleTradeDetail/SampleTradeDetail';
import { GlobalStyle } from 'GlobalStyle';
import cAxios from 'lib/axiosInstance';
if(process.env.NODE_ENV==='development' || process.env.NODE_ENV==='production') {
  messaging.onMessage(payload => {
    console.log('on message.. payload: ', payload)
  })
}
const cx  = classnames.bind(styles);
class App extends Component {
  
  checkToken = async () => {
    console.log('checkToken');
    await cAxios.get('/auth/mgmt/token')
    .then((res)=>{
      const expiresIn = res.data.exp - res.data.iat;
      const exprireTimeLeft = (expiresIn - (Date.now()/1000 - res.data.iat))/60;
      console.log(`만료까지 ${exprireTimeLeft}분 남았습니다.`);
    })
    .catch(e=> {
      console.log('token is invalid or server error! error: ', e.response);
      window.localStorage.removeItem('signInToken');
      this.props.history.push('/signIn')
    })
  }
  componentDidMount() {

    this.checkToken()
    
  }
  
  render() {
    return (
      <div className={cx('App')}>
        <GlobalStyle/>
        {/* <Route exact path='/signIn' component={SignIn}/> */}
        {window.localStorage.getItem("signInToken") &&
          
        <div className={cx('template')}>  
          {/* <Nav /> */}
          <div className={cx('routes')}>
            {/* <Route exact path={'/'} component={SignIn} />
            <Route exact path={'/evaluation-report'} component={EvaluationReport} />
            <Route exact path={'/evaluation-report/:robusta'} component={EvaluationReport} /> */}
            {/* <Route exact path={links.setting} component={AdminSettingPage} />
            <Route exact path={links.samplePackDetail} component={SamplePackDetail} />
            <Route exact path={links.coffeeList} component={CoffeeListPage} />
            <Route exact path={links.coffeeDetail} component={CoffeeDetail} />
            <Route exact path={links.evaluateGreenGrading} component={EvaluateGreenGrading} />
            <Route exact path={links.evaluateCoffee} component={EvaluateGreenGrading} />
            <Route exact path={links.evaluateCupping} component={EvaluateCupping} />
            <Route exact path={links.addSamples} component={AddSamples} />
            <Route exact path={links.sampleTradeList} component={SampleTradeList} />
            <Route exact path={links.sampleTradeDetail} component={SampleTradeDetail} /> */}

          </div>
        </div>
        }
      </div>
    );
  }
}

export default withRouter(App);
