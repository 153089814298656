import { useReducer, useCallback } from 'react';

type DefaultValues = {
  [key: string]: string;
};

type UseInputsAction = {
  type?: string;
  name: string;
  value: string | number | string[];
  data?: any;
};
function reducer<T>(state: T, action: UseInputsAction | null) {
  if (!action) {
    const initialState: any = {};
    Object.keys(state).forEach(key => {
      initialState[key] = '';
    });
    return initialState;
  }
  if (action.type === 'DISPATCH_DATA') {
    return action.data
  }
  return {
    ...state,
    [action.name]: action.value,
  };
}
export default function useInputs<T>(defaultValues: T) {
  const [state, dispatch] = useReducer(reducer, defaultValues);
  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    dispatch({
      name: e.target.name,
      value: e.target.value,
    });
  }, []);
  const onSelect = useCallback((name: string, value: string | number | string[]) => {
    dispatch({
      name: name,
      value: value,
    });

  }, []);

  const dispatchData = useCallback((form: T) => {
    dispatch({
      type: 'DISPATCH_DATA',
      name: '',
      value: '',
      data: form
    })
  }, []);
  return [state, onChange, onSelect, dispatchData] as [
    T,
    typeof onChange,
    typeof onSelect,
    typeof dispatchData
  ];
}