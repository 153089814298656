export const countryCodeList = [
    { "code": "af", "name": "Afghanistan" },
    { "code": "ax", "name": "Aland Islands" },
    { "code": "al", "name": "Albania" },
    { "code": "dz", "name": "Algeria" },
    { "code": "as", "name": "American Samoa" },
    { "code": "ad", "name": "Andorra" },
    { "code": "ao", "name": "Angola" },
    { "code": "ai", "name": "Anguilla" },
    { "code": "error", "name": "error" },
    { "code": "ag", "name": "Antigua" },
    { "code": "ar", "name": "Argentina" },
    { "code": "am", "name": "Armenia" },
    { "code": "aw", "name": "Aruba" },
    { "code": "au", "name": "Australia" },
    { "code": "at", "name": "Austria" },
    { "code": "az", "name": "Azerbaijan" },
    { "code": "bs", "name": "Bahamas" },
    { "code": "bh", "name": "Bahrain" },
    { "code": "bd", "name": "Bangladesh" },
    { "code": "bb", "name": "Barbados" },
    { "code": "by", "name": "Belarus" },
    { "code": "be", "name": "Belgium" },
    { "code": "bz", "name": "Belize" },
    { "code": "bj", "name": "Benin" },
    { "code": "bm", "name": "Bermuda" },
    { "code": "bt", "name": "Bhutan" },
    { "code": "bo", "name": "Bolivia" },
    { "code": "ba", "name": "Bosnia" },
    { "code": "bw", "name": "Botswana" },
    { "code": "bv", "name": "Bouvet Island" },
    { "code": "br", "name": "Brazil" },
    { "code": "vg", "name": "British Virgin Islands" },
    { "code": "bn", "name": "Brunei" },
    { "code": "bg", "name": "Bulgaria" },
    { "code": "bf", "name": "Burkina Faso" },
    { "code": "mm", "name": "Burma" },
    { "code": "bi", "name": "Burundi" },
    { "code": "tc", "name": "Caicos Islands" },
    { "code": "kh", "name": "Cambodia" },
    { "code": "cm", "name": "Cameroon" },
    { "code": "ca", "name": "Canada" },
    { "code": "cv", "name": "Cape Verde" },
    { "code": "ky", "name": "Cayman Islands" },
    { "code": "cf", "name": "Central African Republic" },
    { "code": "td", "name": "Chad" },
    { "code": "cl", "name": "Chile" },
    { "code": "cn", "name": "China" },
    { "code": "cx", "name": "Christmas Island" },
    { "code": "cc", "name": "Cocos Islands" },
    { "code": "co", "name": "Colombia" },
    { "code": "km", "name": "Comoros" },
    { "code": "cd", "name": "Congo" },
    { "code": "cg", "name": "Congo Brazzaville" },
    { "code": "ck", "name": "Cook Islands" },
    { "code": "cr", "name": "Costa Rica" },
    { "code": "ci", "name": "Cote Divoire" },
    { "code": "hr", "name": "Croatia" },
    { "code": "cu", "name": "Cuba" },
    { "code": "cy", "name": "Cyprus" },
    { "code": "cz", "name": "Czech Republic" },
    { "code": "dk", "name": "Denmark" },
    { "code": "dj", "name": "Djibouti" },
    { "code": "dm", "name": "Dominica" },
    { "code": "do", "name": "Dominican Republic" },
    { "code": "ec", "name": "Ecuador" },
    { "code": "eg", "name": "Egypt" },
    { "code": "sv", "name": "El Salvador" },
    { "code": "gq", "name": "Equatorial Guinea" },
    { "code": "er", "name": "Eritrea" },
    { "code": "ee", "name": "Estonia" },
    { "code": "et", "name": "Ethiopia" },
    { "code": "eu", "name": "Europeanunion" },
    { "code": "fk", "name": "Falkland Islands" },
    { "code": "fo", "name": "Faroe Islands" },
    { "code": "fj", "name": "Fiji" },
    { "code": "fi", "name": "Finland" },
    { "code": "fr", "name": "France" },
    { "code": "gf", "name": "French Guiana" },
    { "code": "pf", "name": "French Polynesia" },
    { "code": "tf", "name": "French Territories" },
    { "code": "ga", "name": "Gabon" },
    { "code": "gm", "name": "Gambia" },
    { "code": "ge", "name": "Georgia" },
    { "code": "de", "name": "Germany" },
    { "code": "gh", "name": "Ghana" },
    { "code": "gi", "name": "Gibraltar" },
    { "code": "gr", "name": "Greece" },
    { "code": "gl", "name": "Greenland" },
    { "code": "gd", "name": "Grenada" },
    { "code": "gp", "name": "Guadeloupe" },
    { "code": "gu", "name": "Guam" },
    { "code": "gt", "name": "Guatemala" },
    { "code": "gn", "name": "Guinea" },
    { "code": "gw", "name": "Guinea-Bissau" },
    { "code": "gy", "name": "Guyana" },
    { "code": "ht", "name": "Haiti" },
    { "code": "hm", "name": "Heard Island" },
    { "code": "hn", "name": "Honduras" },
    { "code": "hk", "name": "Hong Kong" },
    { "code": "hu", "name": "Hungary" },
    { "code": "is", "name": "Iceland" },
    { "code": "in", "name": "India" },
    { "code": "io", "name": "Indian Ocean Territory" },
    { "code": "id", "name": "Indonesia" },
    { "code": "ir", "name": "Iran" },
    { "code": "iq", "name": "Iraq" },
    { "code": "ie", "name": "Ireland" },
    { "code": "il", "name": "Israel" },
    { "code": "it", "name": "Italy" },
    { "code": "jm", "name": "Jamaica" },
    { "code": "sj", "name": "Jan Mayen" },
    { "code": "jp", "name": "Japan" },
    { "code": "jo", "name": "Jordan" },
    { "code": "kz", "name": "Kazakhstan" },
    { "code": "ke", "name": "Kenya" },
    { "code": "ki", "name": "Kiribati" },
    { "code": "kw", "name": "Kuwait" },
    { "code": "kg", "name": "Kyrgyzstan" },
    { "code": "la", "name": "Laos" },
    { "code": "lv", "name": "Latvia" },
    { "code": "lb", "name": "Lebanon" },
    { "code": "ls", "name": "Lesotho" },
    { "code": "lr", "name": "Liberia" },
    { "code": "ly", "name": "Libya" },
    { "code": "li", "name": "Liechtenstein" },
    { "code": "lt", "name": "Lithuania" },
    { "code": "lu", "name": "Luxembourg" },
    { "code": "mo", "name": "Macau" },
    { "code": "mk", "name": "Macedonia" },
    { "code": "mg", "name": "Madagascar" },
    { "code": "mw", "name": "Malawi" },
    { "code": "my", "name": "Malaysia" },
    { "code": "mv", "name": "Maldives" },
    { "code": "ml", "name": "Mali" },
    { "code": "mt", "name": "Malta" },
    { "code": "mh", "name": "Marshall Islands" },
    { "code": "mq", "name": "Martinique" },
    { "code": "mr", "name": "Mauritania" },
    { "code": "mu", "name": "Mauritius" },
    { "code": "yt", "name": "Mayotte" },
    { "code": "mx", "name": "Mexico" },
    { "code": "fm", "name": "Micronesia" },
    { "code": "md", "name": "Moldova" },
    { "code": "mc", "name": "Monaco" },
    { "code": "mn", "name": "Mongolia" },
    { "code": "me", "name": "Montenegro" },
    { "code": "ms", "name": "Montserrat" },
    { "code": "ma", "name": "Morocco" },
    { "code": "mz", "name": "Mozambique" },
    { "code": "na", "name": "Namibia" },
    { "code": "nr", "name": "Nauru" },
    { "code": "np", "name": "Nepal" },
    { "code": "nl", "name": "Netherlands" },
    { "code": "an", "name": "Netherlandsantilles" },
    { "code": "nc", "name": "New Caledonia" },
    { "code": "pg", "name": "New Guinea" },
    { "code": "nz", "name": "New Zealand" },
    { "code": "ni", "name": "Nicaragua" },
    { "code": "ne", "name": "Niger" },
    { "code": "ng", "name": "Nigeria" },
    { "code": "nu", "name": "Niue" },
    { "code": "nf", "name": "Norfolk Island" },
    { "code": "kp", "name": "North Korea" },
    { "code": "mp", "name": "Northern Mariana Islands" },
    { "code": "no", "name": "Norway" },
    { "code": "om", "name": "Oman" },
    { "code": "pk", "name": "Pakistan" },
    { "code": "pw", "name": "Palau" },
    { "code": "ps", "name": "Palestine" },
    { "code": "pa", "name": "Panama" },
    { "code": "py", "name": "Paraguay" },
    { "code": "pe", "name": "Peru" },
    { "code": "ph", "name": "Philippines" },
    { "code": "pn", "name": "Pitcairn Islands" },
    { "code": "pl", "name": "Poland" },
    { "code": "pt", "name": "Portugal" },
    { "code": "pr", "name": "Puerto Rico" },
    { "code": "qa", "name": "Qatar" },
    { "code": "re", "name": "Reunion" },
    { "code": "ro", "name": "Romania" },
    { "code": "ru", "name": "Russia" },
    { "code": "rw", "name": "Rwanda" },
    { "code": "sh", "name": "Saint Helena" },
    { "code": "kn", "name": "Saint Kitts and Nevis" },
    { "code": "lc", "name": "Saint Lucia" },
    { "code": "pm", "name": "Saint Pierre" },
    { "code": "vc", "name": "Saint Vincent" },
    { "code": "ws", "name": "Samoa" },
    { "code": "sm", "name": "San Marino" },
    { "code": "gs", "name": "Sandwich Islands" },
    { "code": "st", "name": "Sao Tome" },
    { "code": "sa", "name": "Saudi Arabia" },
    { "code": "sn", "name": "Senegal" },
    { "code": "cs", "name": "Serbia" },
    { "code": "rs", "name": "Serbia" },
    { "code": "sc", "name": "Seychelles" },
    { "code": "sl", "name": "Sierra Leone" },
    { "code": "sg", "name": "Singapore" },
    { "code": "sk", "name": "Slovakia" },
    { "code": "si", "name": "Slovenia" },
    { "code": "sb", "name": "Solomon Islands" },
    { "code": "so", "name": "Somalia" },
    { "code": "za", "name": "South Africa" },
    { "code": "kr", "name": "South Korea" },
    { "code": "es", "name": "Spain" },
    { "code": "lk", "name": "Sri Lanka" },
    { "code": "sd", "name": "Sudan" },
    { "code": "sr", "name": "Suriname" },
    { "code": "sz", "name": "Swaziland" },
    { "code": "se", "name": "Sweden" },
    { "code": "ch", "name": "Switzerland" },
    { "code": "sy", "name": "Syria" },
    { "code": "tw", "name": "Taiwan" },
    { "code": "tj", "name": "Tajikistan" },
    { "code": "tz", "name": "Tanzania" },
    { "code": "th", "name": "Thailand" },
    { "code": "tl", "name": "Timorleste" },
    { "code": "tg", "name": "Togo" },
    { "code": "tk", "name": "Tokelau" },
    { "code": "to", "name": "Tonga" },
    { "code": "tt", "name": "Trinidad" },
    { "code": "tn", "name": "Tunisia" },
    { "code": "tr", "name": "Turkey" },
    { "code": "tm", "name": "Turkmenistan" },
    { "code": "tv", "name": "Tuvalu" },
    { "code": "ae", "name": "U.A.E." },
    { "code": "ug", "name": "Uganda" },
    { "code": "ua", "name": "Ukraine" },
    { "code": "gb", "name": "United Kingdom" },
    { "code": "us", "name": "United States" },
    { "code": "uy", "name": "Uruguay" },
    { "code": "um", "name": "US Minor Islands" },
    { "code": "vi", "name": "US Virgin Islands" },
    { "code": "uz", "name": "Uzbekistan" },
    { "code": "vu", "name": "Vanuatu" },
    { "code": "va", "name": "Vatican City" },
    { "code": "ve", "name": "Venezuela" },
    { "code": "vn", "name": "Vietnam" },
    { "code": "wf", "name": "Wallis and Futuna" },
    { "code": "eh", "name": "Western Sahara" },
    { "code": "ye", "name": "Yemen" },
    { "code": "zm", "name": "Zambia" },
    { "code": "zw", "name": "Zimbabwe" },
    { "code": "us", "name": "Hawaii" },
    { "code": "lt", "name": "Myanmar" },
    // {"code": "gb wls", "name": "Wales"},
    // {"code": "gb sct", "name": "Scotland"},
]

export default countryCodeList;