import React, { Fragment, useEffect, useState } from 'react';
import styles from './CuppingForm.module.scss';
import classnames from 'classnames/bind';
import C2CScore from '../C2CScore';
import { ButtonGroup, Button, Input } from 'react-c2c-ui';

const cx = classnames.bind(styles);

const CuppingForm = ({
    addRow,
    rowArray,
    handleChangeRow,
    removeRow,
    notes,
    noteList,
    inputNotes,
    cancel, submit,
    averages,
    cuppingScore,
    postCuppingError,
    cuppingDate,
    handleChangeDate
}) => {
    return (
        <form onSubmit={submit} className={cx('form')}>
            <table>
                <thead>
                    <tr><td className={cx('empty')} colSpan="13"></td></tr>
                    <tr>
                        <td className={cx('no-border')}></td>
                        <td>Cupper Name</td>
                        <td>Fragrance / Aroma</td>
                        <td>Flavor</td>
                        <td>After Taste</td>
                        <td>Acidity</td>
                        <td>Body</td>
                        <td>Balance</td>
                        <td className={cx("disabled")}>Uniformity</td>
                        <td className={cx("disabled")}>Clean Cup</td>
                        <td className={cx("disabled")}>Sweetness</td>
                        <td>Overall</td>
                        <td>Total</td>
                    </tr>
                </thead>
                <tbody>
                    {rowArray.map((item, index) => (
                        <tr key={index}>
                            <td className={cx('remove-btn')} onClick={()=>{removeRow(index)}}>Delete</td>
                            <td>
                                <select name="name" value={item.name} onChange={(e) => {handleChangeRow(e, index)}}>
                                    <option value="AVERAGE">AVERAGE</option>
                                    <option value="SEO">SEO</option>
                                    <option value="AJIN">AJIN</option>
                                    <option value="FLOR">FLOR</option>
                                    <option value="OTHER">OTHER</option>
                                </select>
                            </td>
                            <td><input min="6" max="10" step="0.25" type="number" onChange={(e) => {handleChangeRow(e, index)}} name="fragrance" value={item.fragrance}/></td>
                            <td><input min="6" max="10" step="0.25" type="number" onChange={(e) => {handleChangeRow(e, index)}} name="flavor" value={item.flavor}/></td>
                            <td><input min="6" max="10" step="0.25" type="number" onChange={(e) => {handleChangeRow(e, index)}} name="afterTaste" value={item.afterTaste}/></td>
                            <td><input min="6" max="10" step="0.25" type="number" onChange={(e) => {handleChangeRow(e, index)}} name="acidity" value={item.acidity}/></td>
                            <td><input min="6" max="10" step="0.25" type="number" onChange={(e) => {handleChangeRow(e, index)}} name="body" value={item.body}/></td>
                            <td><input min="6" max="10" step="0.25" type="number" onChange={(e) => {handleChangeRow(e, index)}} name="balance" value={item.balance}/></td>
                            <td><input min="2" max="10" step="2" type="number" disabled name="uniformity" value={item.uniformity}/></td>
                            <td><input min="2" max="10" step="2" type="number" disabled name="cleanCup" value={item.cleanCup}/></td>
                            <td><input min="2" max="10" step="2" type="number" disabled name="sweetness" value={item.sweetness}/></td>
                            <td><input min="6" max="10" step="0.25" type="number" onChange={(e) => {handleChangeRow(e, index)}} name="overall" value={item.overall}/></td>
                            <td className={cx('total')}>
                                {
                                    Number(item.fragrance)+
                                    Number(item.flavor)+
                                    Number(item.afterTaste)+
                                    Number(item.acidity)+
                                    Number(item.body)+
                                    Number(item.uniformity)+
                                    Number(item.sweetness)+
                                    Number(item.cleanCup)+
                                    Number(item.balance)+
                                    Number(item.overall)
                                }
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td></td>
                        <td colSpan="12" onClick={addRow}>
                            <div className={cx('plus-row')}>
                                <h1>Add</h1>
                            </div>
                        </td>
                    </tr>
                    <tr className={cx('none_input-row')}>
                        <td className={cx('no-border')}></td>
                        <td className={cx('bold', 'no-border')}>Average</td>
                        <td>{(averages.fragrance)}</td>
                        <td>{(averages.flavor)}</td>
                        <td>{(averages.afterTaste)}</td>
                        <td>{(averages.acidity)}</td>
                        <td>{(averages.body)}</td>
                        <td>{(averages.balance)}</td>
                        <td className={cx("disabled")}>{(averages.uniformity)}</td>
                        <td className={cx("disabled")}>{(averages.cleanCup)}</td>
                        <td className={cx("disabled")}>{(averages.sweetness)}</td>
                        <td>{(averages.overall)}</td>

                        <td className={cx('total')}>{cuppingScore}</td>
                    </tr>

                    <tr>
                        <td></td>
                        <td className={cx('bold')}>Notes</td>
                        <td className={cx('notes')} colSpan="10">
                            <textarea
                                type="text"
                                value={notes}
                                onChange={(e)=>{inputNotes(e.target.value)}}
                                placeholder="콤마(,)를 이용해 Notes를 구분해주세요."
                            />
                            <div className={cx('note-list')}>
                                {
                                    noteList.map(item => (
                                            <div className={cx('item')}>
                                                {item}
                                            </div>
                                        )
                                    )
                                }
                            </div>
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td className={cx('bold')} colSpan="2">Cupping Date</td>
                        <td className={cx('notes')} colSpan="2">
                            <input
                                type="date"
                                value={cuppingDate}
                                onChange={handleChangeDate}
                            />
                        </td>
                        <td></td>
                    </tr>
                    <tr><td className={cx('empty')} colSpan="13"></td></tr>
                </tbody>
            </table>

            {/* <C2CScore 
                cuppingScore={cuppingScore}
            /> */}
            <ButtonGroup rightAlign style={{marginTop:'3rem'}}>
                <Button type="button" theme="tertiary" onClick={cancel}>Cancel</Button>
                <Button type="submit">Submit</Button>
            </ButtonGroup>
            <p style={{marginTop: '1rem', textAlign: 'right', color: 'red'}}>{postCuppingError}</p>
        </form>
    );
};

export default CuppingForm;