import React from 'react'
import styled, { css } from 'styled-components';
import { rem, utils, flex, palette, media, InfoItem } from 'react-c2c-ui';

interface OwnProps {
    tradeStatus: 'IN_TRANSACTION'|'PAID'|'DELIVERY'|'COMPLETE';
    tradeId: number|string;
    address: string;
    buyer: string;
    samples: string;
    totalPrice: string;
    onClick: any;
}
type Props = OwnProps;

const SampleTradeItem: React.SFC<Props> = ({
    tradeStatus,
    tradeId,
    address,
    buyer,
    samples,
    totalPrice,
    onClick
}) => {
    return (
        <SampleTradeItemBlock onClick={onClick} status={tradeStatus}>
            <div className="row">
                <p>Trade Id. <span>{tradeId}</span><span className='status'> (status: {tradeStatus})</span></p>
                <p>To. <span>{address}</span></p>
            </div>
            <div className="row-3">
                <InfoItem label='Buyer' value={buyer}/>
                <InfoItem label='Samples' value={samples}/>
                <InfoItem label='TotalPrice' value={totalPrice}/>
            </div>
        </SampleTradeItemBlock>
    )
}

const SampleTradeItemBlock = styled.article<{status:'IN_TRANSACTION'|'PAID'|'DELIVERY'|'COMPLETE'}>`
    padding: ${rem(20)};
    border: 1px solid ${palette.gray400};
    cursor: pointer;
    &:hover {
        border-color: ${palette.gray600};
    }
    >.row {
        display: flex;
        margin-bottom: ${rem(10)};
        padding-bottom: ${rem(20)};
        border-bottom: 1px solid ${palette.gray300};
        >p {
            color: ${palette.gray500};
            >span {
                font-weight: 700;
                color: black;
            }

            >.status {
                color: ${props => {
                    if(props.status === 'COMPLETE') {
                        return palette.grayGreen;
                    } else if(props.status === 'DELIVERY') {
                        return palette.grayGreen;
                    } else if(props.status === 'PAID') {
                        return palette.blue500;
                    } else {
                        return palette.gray500;
                    }
                }}
            }
        }
        >p + p {
            margin-left: ${rem(20)};
            padding-left: ${rem(20)};
            border-left: 1px solid ${palette.gray400};
        }
    }

    >.row-3 {
        display: flex;
        flex-wrap: wrap;
        >div {
            width: auto;
            margin-top: ${rem(10)};
        }

        >:first-child {
            flex-basis: 30%;
            margin-right: ${rem(20)};
        }
        >:nth-child(2) {
            flex-basis: 60%;
            margin-right: ${rem(20)};
        }
    }
`;

export default SampleTradeItem;