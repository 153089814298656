import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components';
import { rem, utils, flex, palette, media, ButtonGroup, Button, Modal, Input } from 'react-c2c-ui';
import useRequest from 'lib/hooks/useRequest';
import useInputs from 'lib/hooks/useInputs';
import HeaderTemplate from 'components/_templates/HeaderTemplate';
import { links } from 'lib/links';
import { withRouter, RouteComponentProps } from 'react-router';
import cAxios from 'lib/axiosInstance';

interface OwnProps {
    coffeeId: number;
    selectedSamplePackId: number;
}
type Props = OwnProps&RouteComponentProps;

const SamplePackList: React.SFC<Props> = ({coffeeId, history, selectedSamplePackId}) => {
    //# GET_SAMPLE_PACKS
    const [
        onRequestGetSamplePacks,
        getSamplePacksResult,
        getSamplePacksLoading,
        getSamplePacksError
    ] = useRequest<any>(() =>
        cAxios.get(
            `/coffee/mgmt/${coffeeId}/samplePacks`
        )
    );
    const getSamplePacks = async () => {
        const data = await onRequestGetSamplePacks();
        return data;
    }
    useEffect(() => {
        const data = getSamplePacks();
    }, [])

    //# RECEIVE_SAMPLEPACK
    const [receivedSamplePackForms, onChangeReceivingSampleForm, onSelect, dispatchData] = useInputs<{ sampleWeight: number | undefined, receiveSampleDate: string | undefined }>({
        sampleWeight: 0,
        receiveSampleDate: new Date().toJSON().slice(0,10)
    })

    const [receivingSampleModal, setReceivingSampleModal] = useState({
        samplePackId: -1,
        open: false
    })

    const openPostReceivingSampleModal = (samplePackId: number) => {
        setReceivingSampleModal({
            samplePackId,
            open: true
        })
    }

    const openPutReceivingSampleModal = (samplePackId: number, sampleWeight: number, receiveSampleDate: string) => {
        dispatchData({
            sampleWeight, receiveSampleDate
        })
        setReceivingSampleModal({
            samplePackId,
            open: true
        })
    }

    const closeReceivingSampleModal = () => {
        dispatchData({
            sampleWeight: 0, receiveSampleDate: new Date().toJSON().slice(0,10)
        })
        setReceivingSampleModal({
            samplePackId: -1,
            open: false
        })
    }

    const [
        onRequestReceiveSample,
        receiveSampleResult,
        receiveSampleLoading,
        receiveSampleError
    ] = useRequest<any>((data: any, samplePackId: number) =>
        cAxios.post(
            `/sample/mgmt/samplePacks/receive/${samplePackId}`,
            data
        )
    );
    const confirmReceivingSampleModal = async () => {
        const samplePackId = receivingSampleModal.samplePackId;
        const data = {
            sampleWeight: receivedSamplePackForms.sampleWeight,
            receiveSampleDate: receivedSamplePackForms.receiveSampleDate
        }
        await onRequestReceiveSample(data, samplePackId);
        await getSamplePacks();
        closeReceivingSampleModal();
    }

    //# SET_VALID_INVALID
    const setInvalid = async (samplePackId: number) => {
        const res = await cAxios.post(
            `/sample/mgmt/samplePacks/setInvalid/${samplePackId}`
        )
        await getSamplePacks();
    }

    const setValid = async (samplePackId: number) => {
        const res = await cAxios.post(
            `/sample/mgmt/samplePacks/setValid/${samplePackId}`
        )
        await getSamplePacks();
    }

    return (
        <SamplePackListBlock>
            {getSamplePacksResult &&
                getSamplePacksResult.samplePacks.map((item: any) => {
                    const hasSamples = item.samples.length > 0;
                    if (item.inValidTrackingNumber) { //# INVALID
                        return (
                            <InValidBox key={item.id}>
                                <div className="header">
                                    <h3>유효하지 않은 운송장 정보(samplePackId: {item.id})</h3>
                                </div>
                                <div className="body">
                                    <div>
                                        <p>courier: {item.courier}</p>
                                        <p>tracking number: {item.trackingNumber}</p>
                                    </div>
                                    <ButtonGroup>
                                        <Button size="small" disabled>배송정보 재요청 이메일 보내기</Button>
                                        <Button size="small" className="gray-btn" onClick={() => { setValid(item.id) }}>유효한 배송정보로 되돌리기</Button>
                                    </ButtonGroup>
                                </div>
                            </InValidBox>
                        )
                    }
                    if (item.status === "SENDING") { //# SENDING
                        return (
                            <SendingSamplePackBox key={item.id}>
                                <div className="header">
                                    <h3>셀러가 샘플을 발송하였습니다.(samplePackId: {item.id})</h3>
                                </div>
                                <div className="body">
                                    <div>
                                        <p className="description">
                                            운송장 정보를 확인하여 유효하지 않은 정보는 ‘배송정보가 유효하지 않음’ 버튼을 눌러 반려하고, 샘플 수령 시에는 ‘샘플수령'버튼을 클릭하여 추가 정보를 입력해주세요
                                        </p>
                                        <p>courier: {item.courier}</p>
                                        <p>tracking number: {item.trackingNumber}</p>
                                    </div>
                                    <ButtonGroup className="btn-group" rightAlign>
                                        <Button size="small" className="red" onClick={() => { setInvalid(item.id) }}>배송정보가 유효하지 않음</Button>
                                        <Button
                                            size="small"
                                            onClick={() => { openPostReceivingSampleModal(item.id) }}
                                        >
                                            샘플 수령
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </SendingSamplePackBox>
                        )
                    } else if (item.status === "RECEIVED") { //# RECEIVED
                        return (
                            <ReceivedBox isRegistered={hasSamples} key={item.id}>
                                <div className="header">
                                    <h3>샘플수령완료(samplePackId: {item.id})</h3>
                                    <p>품질평가 정보를 입력해야 합니다.</p>
                                </div>

                                <div className="body">
                                    <div>
                                        <p>운송장 정보: {item.courier}-{item.trackingNumber}</p>
                                        <p>
                                            샘플 받은 날짜: {
                                            item.receiveSampleDate
                                                ?item.receiveSampleDate.substring(0,10)
                                                :"undefined"
                                            }
                                        </p>
                                            
                                        <p>받은 샘플 무게: {item.sampleWeight}</p>
                                    </div>

                                    <div>
                                        <ButtonGroup>
                                            <Button size="small"
                                                onClick={() => {openPutReceivingSampleModal(item.id, item.sampleWeight, item.receiveSampleDate.substring(0,10))}}
                                            >샘플팩 수령정보 수정하기</Button>
                                            <Button size="small"
                                                onClick={()=>history.push(links.samplePackDetail.replace(':samplePackId', item.id))}
                                            >샘플 팩 관리하기</Button>

                                        </ButtonGroup>
                                    </div>
                                </div>
                            </ReceivedBox>
                        )
                    } else if (item.status === "COMPLETE") {
                        return (
                            <CompleteBox key={item.id}>
                                <div className="header">
                                    <h3>샘플 품질평가 완료(samplePackId: {item.id})</h3>
                                    <p></p>
                                </div>
                                <div className="body">
                                    <div>
                                        { item.id === selectedSamplePackId
                                            ?
                                            <p>ID:{coffeeId} 커피가 이 샘플팩의 정보를 사용하고 있습니다.</p>
                                            :
                                            <p>품질평가는 되었으나 정보를 사용되고 있지 않습니다. 샘플 팩 관리를 통해 변경 가능합니다.</p>
                                        }
                                    </div>
                                    <Button size="small"
                                        onClick={()=>history.push(links.samplePackDetail.replace(':samplePackId', item.id))}
                                    >샘플 팩 관리하기</Button>
                                </div>
                            </CompleteBox>
                        )
                    } else if (item.status === "") {
                        return <div />
                    } else if (item.status === "") {
                        return <div />
                    } else {
                        return <div></div>
                    }

                })
            }

            <Modal
                cancellable
                visible={receivingSampleModal.open}
                onCancel={closeReceivingSampleModal}
                onConfirm={confirmReceivingSampleModal}
            >
                <div className="receive_sample-box">
                    <h3>샘플 수령 정보 입력</h3>
                    <Input
                        label="샘플 수령 날짜"
                        name="receiveSampleDate"
                        required
                        type="date"
                        value={receivedSamplePackForms.receiveSampleDate}
                        onChange={onChangeReceivingSampleForm}
                    />

                    <Input
                        label="샘플 무게"
                        required
                        symbol="gram"
                        type="number"
                        name="sampleWeight"
                        value={receivedSamplePackForms.sampleWeight}
                        onChange={onChangeReceivingSampleForm}
                    />
                </div>
            </Modal>
        </SamplePackListBlock>
    )
}

const SamplePackListBlock = styled.div`
    
`;

const samplePackBoxStyle = css`
    padding: ${rem(10)} ${rem(20)};
    ${utils.shadow};
    margin-top: ${rem(20)};
`;
const boxStyle = css`
    background: ${palette.gray100};
    ${utils.shadow};
    margin-top: ${rem(20)};
`;

const headerStyle = css`
    ${flex.row};
    ${flex.jc.spaceB};
    ${flex.ai.center};
    padding: ${rem(10)};
    background: ${palette.blue100};

    >h3 {
        font-size: ${rem(18)};
        font-weight: 500;
    }

    >p {
        font-size: ${rem(12)};
        color: ${palette.blue600};
    }
`;

const bodyStyle = css`
    ${flex.row};
    ${flex.ai.end};
    ${flex.jc.spaceB};
    padding: ${rem(10)};
`;

const SendingSamplePackBox = styled.div`
    ${boxStyle};
    >.header {
        ${headerStyle};
        background: ${palette.gray400};
    }

    >.body {
        ${bodyStyle};
        .description {
            max-width: ${rem(550)};
        }
        >.btn-group {
            margin-top: ${rem(20)};
            flex: none;
            >.red {
                background: ${palette.red500};
                &:hover {
                    background: ${palette.red600};
                }
            }
        }
    }

`;

const ReceivedBox = styled.div<{ isRegistered: boolean }>`
    ${boxStyle};
    >.header {
        ${headerStyle};
    }

    >.body {
        ${bodyStyle};
    }
`;
const CompleteBox = styled.div`
    ${boxStyle};
    >.header {
        ${headerStyle};
        background: #6FCF97;
    }

    >.body {
        ${bodyStyle};
    }
`;

const InValidBox = styled.div`
    ${boxStyle};

    .header {
        ${headerStyle};
        background: ${palette.red100};
    }

    .body {
        ${bodyStyle};
        >:nth-child(2) {
            .gray-btn {
                background: ${palette.gray700};
                &:hover {
                    background: ${palette.gray800};
                }
            }
        }
    }

`;


export default withRouter(SamplePackList);