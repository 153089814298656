import React from 'react'
import styled, { css } from 'styled-components';
import { rem, utils, flex, palette, media } from 'react-c2c-ui';

interface OwnProps {
    samples: {
        note: string;
        id: number;
        purpose: string;
        price: number | string;
    }[];
}
type Props = OwnProps;

const SamplePackSampleList: React.SFC<Props> = ({ samples }) => {
    return (
        <div>
            {samples.map((item: any) => (
                <SampleItemBlock key={item.id}>
                    <div>
                        <p className='info'>샘플 ID:{item.id} / 샘플무게: {item.amount}gram / 샘플사용처: {item.purpose} / 샘플가격: {item.price}USD</p>
                        
                        {/* //TEMP  */}
                        {/* <p className='btn'>자세히 보기</p> */}
                    </div>
                    {item.note && <p className="note">Note: {item.note}</p>}
                </SampleItemBlock>
            ))}
        </div>
    )
}

const SampleItemBlock = styled.div`
    padding: ${rem(10)} ${rem(20)};
    ${utils.shadow};
    margin-bottom: ${rem(10)};
    background: #fafafa;
    >div {
        ${flex.row};
        ${flex.jc.spaceB};
        >p {
            font-size: ${rem(12)};
        }
        >.btn {
            text-decoration: underline;
            color: ${palette.blue500};
            margin-left: ${rem(20)};
            cursor: pointer;
            &:hover {
                color: ${palette.blue600};
            }
        }
    }
    >.note {
        color: ${palette.gray600};
        font-size: ${rem(12)};
        margin-top: ${rem(5)};
    }
`;

export default SamplePackSampleList;