import React, { useRef, SFC, useState, useEffect } from 'react'
import styled, { css } from 'styled-components';
import { rem, utils, flex, palette, media, InfoItem, InfoGroup, Button, Input } from 'react-c2c-ui';
import PaperWrapper from 'components/PaperWrapper';
import { getEvaluationJson } from 'lib/getJsonFromSheet';
import { RouteComponentProps } from 'react-router';
import CuppingChart from 'components/CuppingChart';
import CuppingChartRobusta from 'components/CuppingChartRobusta';

interface OwnProps { };
type Props = OwnProps & RouteComponentProps<{ robusta: string }>
const EvaluationReport: SFC<Props> = ({ match }) => {
    const [renderRoot, setRenderRoot] = useState(true);
    const print = () => {
        setRenderRoot(false);
    }
    const goToExel = () => {
        if(match.params.robusta) {
            window.open(`https://docs.google.com/spreadsheets/d/12rmQ32uClMdxY0ip2wLT-BLN8JBUzJjanJQPQo9bers/edit#gid=776216996`, '_blank');
        } else {
            window.open(`https://docs.google.com/spreadsheets/d/12rmQ32uClMdxY0ip2wLT-BLN8JBUzJjanJQPQo9bers/edit#gid=2141782562`, '_blank');
        }
    }
    useEffect(() => {
        const root = document.getElementById('root');
        const paperWrapper = document.getElementById('paper-wrapper');
        const fixedHeader = document.getElementById('fixed-header');
        const fixedFooter = document.getElementById('fixed-footer');
        if (!renderRoot && root && paperWrapper && fixedHeader && fixedFooter) {
            document.title = `[${evaluationInfos[0].company}] Coffee Evaluation Report (${evaluatedDate.slice(2)})`;
            root.style.display = 'none';
            fixedHeader.style.display = 'block';
            fixedFooter.style.display = 'block';
            paperWrapper.style.top = '0';
            window.print();
            window.location.reload();
        }
    }, [renderRoot])

    const [evaluationInfos, setEvaluationInfos] = useState<any[]>([]);
    const getJson = async () => {
        const sheetNum = match.params.robusta?3:2;
        const jsonList = await getEvaluationJson(sheetNum);
        console.log('jsonList', jsonList);
        setEvaluationInfos(jsonList);
    }
    useEffect(() => {
        getJson();
    }, [])
    const [evaluatedDate, setEvaluatedDate] = useState(new Date().toJSON().slice(0, 10))
    const handleChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEvaluatedDate(e.target.value);
    }
    return (
        <EvaluationReportBlock>
            <div>
                <Input label="품질평가 날짜" width="100%" type="date" value={String(evaluatedDate)} onChange={handleChangeDate} />
                <Button full onClick={print}>PRINT</Button>
                <Button full onClick={goToExel} theme="secondary">Edit Data</Button>
            </div>
            <PaperWrapper id='paper-wrapper' evaluatedDate={String(evaluatedDate)}>
                {evaluationInfos.map((item, index) => {
                    const {
                        c2cId, company, country, region, specificRegion,
                        variety, processing,
                        moisture, density, waterActivity, flavorNote,
                        cuppingScore, florsNote,

                        acidity, afterTaste, balance,
                        body, flavor, aroma, overall,

                        text
                    } = item;

                    return (
                        <ItemBox isOdd={index % 2 === 1} key={String(index)+c2cId}>
                            <div className="inner">
                                <EvaluationItem>
                                    <div className='header'>
                                        <h3>[<span>{c2cId}</span>] Coffee Sample Evaluation Report</h3>
                                        <p>{country&&country}{region && `, ${region}`}{specificRegion && `, ${specificRegion}`}. {variety ? `${variety}. ` : ""}{processing ? `${processing}. ` : ""}{florsNote ? `${florsNote}.` : ""}</p>
                                    </div>
                                    <div className='body'>
                                        <div className="gg">
                                            <div className="gg-box row-3">
                                                <InfoItem label="Moisture" value={moisture?`${moisture} %`:null} />
                                            
                                            
                                                <InfoItem label="Density" value={density?`${density} g/l `:null} />
                                            
                                            
                                                <InfoItem label="Water Activity" value={waterActivity?`${waterActivity} aw`:null} />
                                            </div>
                                            {text && <p className="gg-box empty">{text}</p>}
                                        </div>
                                        {match.params.robusta
                                            ?
                                            <div className='row'>
                                                <div className='text_info-box'>
                                                    <InfoItem row label="Cupping Score" labelWidth={100} value={cuppingScore} />
                                                    <InfoItem row label="Fragrance/Aroma" labelWidth={100} value={Number(item.aroma).toFixed(2)} />
                                                    <InfoItem row label="Flavor" labelWidth={100} value={Number(item.flavor).toFixed(2)} />
                                                    <InfoItem row label="Aftertaste" labelWidth={100} value={Number(item.afterTaste).toFixed(2)} />
                                                    <InfoItem row label="Salt/Acidity" labelWidth={100} value={Number(item.saltAcidity).toFixed(2)} />
                                                    <InfoItem row label="Bitter/Sweet" labelWidth={100} value={Number(item.bitterSweet).toFixed(2)} />
                                                    <InfoItem row label="Mouth feel" labelWidth={100} value={Number(item.mouthfeel).toFixed(2)} />
                                                    <InfoItem row label="Balance" labelWidth={100} value={Number(item.balance).toFixed(2)} />
                                                </div>
                                                <div className='cupping_chart'>
                                                    <CuppingChartRobusta
                                                        aroma={item.aroma}
                                                        flavor={item.flavor}
                                                        afterTaste={item.afterTaste}
                                                        saltAcidity={item.saltAcidity}
                                                        bitterSweet={item.bitterSweet}
                                                        mouthfeel={item.mouthfeel}
                                                        balance={item.balance}
                                                    />
                                                </div>
                                            </div>

                                            :
                                            <div className='row'>
                                                <div className='text_info-box'>
                                                    <InfoItem row label="Cupping Score" labelWidth={100} value={cuppingScore} />
                                                    <InfoItem row label="Fragrance / Aroma" labelWidth={100} value={Number(aroma).toFixed(2)} />
                                                    <InfoItem row label="Flavor" labelWidth={100} value={Number(flavor).toFixed(2)} />
                                                    <InfoItem row label="Aftertaste" labelWidth={100} value={Number(afterTaste).toFixed(2)} />
                                                    <InfoItem row label="Acidity" labelWidth={100} value={Number(acidity).toFixed(2)} />
                                                    <InfoItem row label="Body" labelWidth={100} value={Number(body).toFixed(2)} />
                                                    <InfoItem row label="Balance" labelWidth={100} value={Number(balance).toFixed(2)} />
                                                    <InfoItem row label="Overall" labelWidth={100} value={Number(overall).toFixed(2)} />
                                                </div>
                                                <div className='cupping_chart'>
                                                    <CuppingChart
                                                        acidity={acidity}
                                                        afterTaste={afterTaste}
                                                        balance={balance}
                                                        body={body}
                                                        flavor={flavor}
                                                        fragrance={aroma}
                                                        overall={overall}
                                                    />

                                                </div>
                                            </div>
                                        }   
                                    </div>

                                    <div className="footer">
                                        {flavorNote &&
                                            <InfoItem className="flavor-note" row label="Flavor Note" labelWidth={100} value={flavorNote} />
                                        }
                                    </div>
                                </EvaluationItem>
                            </div>
                        </ItemBox>
                    )

                })}

            </PaperWrapper>
        </EvaluationReportBlock>
    )
}

const EvaluationReportBlock = styled.div`
    position: fixed;
    bottom:80px;
    right: 80px;
    z-index: 1;
    >:first-child {
        width: ${rem(200)};
    }
    
`;

const EvaluationItem = styled.div`
    border: 1px solid ${palette.gray300};
    ${utils.shadow};
    padding: 5mm;
    width: 190mm;
    height: 120mm;
    margin: 0 auto;
    overflow-y: hidden;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    >.header {
        height: 14mm;
        >h3 {
            >span {
                font-weight: 500;
                color: ${palette.gray600};
            }
        }

        >p {
            font-size: 14px;
        }
    }
    >.body {
        ${flex.col};
        ${flex.jc.center};
        height: 88mm;
        >.gg {
            position: relative;
            >.gg-box {
                margin: 0 0 3mm 0;
                width: 100%;
                height: 11mm;
                display: flex;
                align-items: center;
                background: ${palette.gray200};
                
            }
            >.row-3 {
                justify-content: space-between;
                >div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
                >:first-child, >:nth-child(2) {
                    border-right: 1px solid white;
                }
            }
            >.empty {
                width: 100%;
                height: 11mm;
                position: absolute;
                top: 0;
                background: rgba(0,0,0,0.6);
                justify-content: center;
                font-style: italic;
                color: ${palette.gray100};
                font-size: 4mm;
            }
        }
        >.row {
            display: flex;
            width: 100%;
            border: 1px solid ${palette.gray200};
            padding: 2mm;
            >.text_info-box {
                width: 100%;
                ${flex.col};
                ${flex.jc.center};
                flex: 0 1;
                >:first-child {
                    >.value {
                        color: ${palette.blue500}
                    }
                }
                >* {
                    margin-bottom: 5px;
                }
                >div {
                    >:first-child { /* InfoItem - label */
                        font-weight: 500;
                        color: gray;
                    }
                }
            }
            >.cupping_chart {
                /* 프린트 설정에서 차트가 잘리는 현상 방지 */
                height: 55mm;
                overflow-y: hidden;
                display: flex;
                align-items: center;
                flex: 1 0;
                justify-content: center;
            }
        }
        
        
    }
    >.footer {
        height: 18mm;
        display: flex;
        >:first-child { /* InfoItem */
            align-items: flex-start;
            padding-left: 2mm;
            >:first-child { /* InfoItem - label */
                font-weight: 500;
                color: gray;
            }

            >:nth-child(2) {
                font-size: 12px;
            }
        }
    }
`;

const ItemBox = styled.div<{ isOdd: boolean }>`
    ${props => props.isOdd
        ?
        css`
            padding-bottom: 20mm;
            >.inner {
                height: 120mm;
                overflow-y: hidden;
                /* background: green; */
                color: white;
            }
        `
        :
        css`
            padding-top: 30mm;
            padding-bottom: 7mm;
            >.inner {
                height: 120mm;
                overflow-y: hidden;
                /* background: blue; */
                color: white;

            }
        `
    };
`;



export default EvaluationReport;