import React from 'react'
import styled, { css } from 'styled-components';
import { rem, utils, flex, palette, media } from 'react-c2c-ui';

interface OwnProps {
    receivedWeight: number|string;
    roastroastedWeighted: number;
    defectsWeight: number;
    registeredSampleQuantity: number;
    registeredSampleTotalWeight: number;
    style?: React.CSSProperties;
}
type Props = OwnProps;

const RemainingWeightDetail: React.SFC<Props> = ({
    receivedWeight,
    roastroastedWeighted,
    defectsWeight,
    registeredSampleQuantity,
    registeredSampleTotalWeight,
    style
}) => {
    return (
        <RemainingWeightDetailBlock style={style}>
            <p>받은 샘플 팩 무게: {receivedWeight}g</p>
            <p>품질평가에 사용된 샘플 무게: {roastroastedWeighted}gram</p>
            <p>Defects 무게: {defectsWeight}gram</p>
            <p>샘플 등록된 무게({registeredSampleQuantity}건): {registeredSampleTotalWeight}g</p>
        </RemainingWeightDetailBlock>
    )
}

const RemainingWeightDetailBlock = styled.div`
    padding-left: ${rem(10)};
    margin: ${rem(5)} 0 ${rem(20)} 0;
    padding-bottom: ${rem(20)};
    border-bottom: 1px solid ${palette.gray400};
    >p {
        font-size: ${rem(12)};
        color: ${palette.gray700};
        margin-bottom: ${rem(5)};
    }
`;

export default RemainingWeightDetail;