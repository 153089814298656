import React, { Component } from 'react';

// hoc
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

// redux
import {connect} from 'react-redux';

import styles from './SignIn.module.scss';
import classnames from 'classnames/bind';
import Input from 'components/_inputs/Input';
import { links } from 'lib/links';
import cAxios from 'lib/axiosInstance';


const cx = classnames.bind(styles);

const validationOption = {
    email:{
        require:true,
        type: 'email'
    },
    password:{
        require:true,
        type: 'password'
    }
}
class SignIn extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            form:{}, error:{}
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
        if(window.localStorage.getItem('signInToken')) {
            // this.props.history.push(links.coffeeList+'?page=1');
            this.props.history.push(`/evaluation-report`);
        }
    }
    
    handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const data = await cAxios.post(
                '/auth/mgmt/signIn',
                {
                    email: this.state.form.email,
                    password: this.state.form.password
                }
            )
            console.log('data', data);
            window.localStorage.setItem('signInToken', data.data.signInToken);
            // this.props.history.push(links.coffeeList+`?page=1`)
            this.props.history.push(`/evaluation-report`);
            
        } catch(e) {
            console.log('e :', e);
        }
    }

    handleChange(name, value) {
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        });
    }
    
    render() {
        const { form, error } = this.state;
        const { handleChange, handleSubmit } = this;
        return (
            <div className={cx('container')}>
                <form onSubmit={handleSubmit}>
                    <h1>C2C Platform Management</h1>
                    <Input
                        name='email'
                        label='Email'
                        value={form.email}
                        error={error.email}
                        onChange={handleChange} 
                        require={validationOption.email.require}
                        type={validationOption.email.type}
                        placeholder='Enter Email'
                    />
                    <Input
                        name='password'
                        label='Password'
                        value={form.password}
                        error={error.password}
                        onChange={handleChange} 
                        require={validationOption.password.require}
                        type={validationOption.password.type}
                        placeholder='Enter Password'
                    />
                    <button onSubmit={handleSubmit}>Sign In</button>
                </form>
            </div>
        );
    }
}


let mapStateToProps = (state) => {
    return {

    }
}
let mapDispatchToProps = (dispatch) => {
    return {

    }
}
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(SignIn);