import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components';
import { rem, utils, flex, palette, media, Button } from 'react-c2c-ui';
import HeaderTemplate from 'components/_templates/HeaderTemplate';
import useRequest from 'lib/hooks/useRequest';
import Switch from "react-switch";
import { messaging } from 'lib/firebase';
import cAxios from 'lib/axiosInstance';

interface OwnProps {

}
type Props = OwnProps;

const AdminSettingPage: React.SFC<Props> = ({}) => {
    //# SUBSCRIBE
    const [isSubscribed, setIsSubscribed] = useState(false);
    const updateToken = async (token: string|null) => {
        console.log('Update FCM Token', JSON.stringify(token));
        await cAxios.post('/user/fcmToken', {token})
    }

    const subscribe = () => {
        if(process.env.NODE_ENV==='development' || process.env.NODE_ENV==='production') {
            messaging.getToken().then((currentToken) => {
                if (currentToken) {
                    setIsSubscribed(true);
                    updateToken(currentToken);
                } else {
                    // Show permission request.
                    console.log('No Instance ID token available. Request permission to generate one.');
                    setIsSubscribed(false);
                    updateToken(null);

                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
            });
        } else {
            console.log('subscribe: localhost와 https에서만 작동합니다.');
        }
        
    }
    const unsubscribe = () => {
        if(process.env.NODE_ENV==='development' || process.env.NODE_ENV==='production') {
            messaging.getToken().then((currentToken) => {
                messaging.deleteToken(currentToken).then(() => {
                    console.log('token deleted')
                    updateToken(null);
                    setIsSubscribed(false);
                })
            })
        } else {
            console.log('unsubscribe: localhost와 https에서만 작동합니다.');
        }

    }
    const handleSubsribeButtonClick = () => {
        if (isSubscribed) {
            unsubscribe();
          } else {
            subscribe();
          }
    }

    //# GET USER
    const [
        onRequestGetUser,
        getUserResult,
        getUserLoading,
        getUserError
    ] = useRequest<any>(()=>
        cAxios.get('/user')
    );
    const getUser = async () => {
        const data = await onRequestGetUser();
        setIsSubscribed(!(data.userConfig.registrationToken === null))
    }

    useEffect(() => {
        getUser();
    }, [])

    if(getUserResult) {
        return (
            <AdminSettingPageBlock>
                <HeaderTemplate
                    backText="home으로 돌아갑니다."
                    backTo="/"
                    headerText="Admin Setting page"
                />
                
                <div className='contents'>
                    <h3>Admin: {getUserResult.email}</h3>
                    <SettingRow>
                        <div className='row'>
                            <h3>알림 설정</h3>
                            <Switch
                                onChange={handleSubsribeButtonClick}
                                checked={isSubscribed}
                                disabled={Notification.permission === 'denied'}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                height={20}
                                width={40}
                            />
                        </div>
                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolorum, beatae.</p>
                    </SettingRow>
                </div>
            </AdminSettingPageBlock>
        )
    } else {
        if(getUserLoading) return <p>loading...</p>
        if(getUserError) return <p>{JSON.stringify(getUserError)}</p>
        return <p>No result</p>
    }
}

const AdminSettingPageBlock = styled.div`
    >.contents {
        margin-top: ${rem(40)};
        >h3 {
            margin-bottom: ${rem(20)};
            padding-bottom: ${rem(20)};
            border-bottom: 1px solid ${palette.gray300};
        }
    }
`;

const SettingRow = styled.div`
    margin-bottom: ${rem(20)};
    padding-bottom: ${rem(20)};
    border-bottom: 1px solid ${palette.gray300};
    >.row {
        ${flex.row};
        ${flex.jc.spaceB};
        margin-bottom: ${rem(10)};
        >h3 {

        }

        >button {

        }
    }
    >p {
        color: ${palette.gray800};
    }
`;

export default AdminSettingPage;