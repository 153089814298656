import validator from 'validator';
import _ from 'lodash';

export function checkValidation(value, option){
    const require = option.require;
    const type = option.type;
    if(require) { // require === true
        // empty
        if(!value || _.isEqual(value, [])) {
            return 'valid.empty'
        }
    }

    switch(type) {
        case 'ST10': if(value>10) {
            return 'valid.biggerThan10';
        } else {
            return '';
        }
        case 'ST100': if(value>100) {
            return 'valid.biggerThan100';
        } else {
            return '';
        }

        case 'email': if(!validator.isEmail(value)){
            return 'valid.email'
        } else {
            return ''
        }

        case 'password': if(!validator.isLength(value, {min: 6})){ 
            return 'valid.password'
        } else {
            return ''
        }

        case 'range': 
            if(!_.isEmpty(toString(value))) {
                if(_.includes(value, '~')) {
                    const value1 = value.split('~')[0];
                    const value2 = value.split('~')[1];
                    if(value2 && !value1) {
                        return 'valid.frontEmpty';
                    } else if(Number(value1)>Number(value2)) {
                        return 'valid.gt'
                    } else if(!validator.isInt(value1) || !validator.isInt(value2)) {
                        return 'valid.number'
                    } else {
                        return ''
                    }
                } else {
                    if(require && !value ) {
                        return 'valid.empty'
                    } else {
                        return ''
                    }
                }
            } else {
                if(require && !value ) {
                    return 'valid.empty'
                } else {
                    return ''
                }
            }
        
        default: return ''
    }
}

export function initError(form, validationOption) {
    return _.mapValues(validationOption, (o, key) => {
        return checkValidation(form[key], validationOption[key]);
    })
}

export function mkHasError(form, validationOption) {
    let isAllRequireEmpty = false;
    _.map(validationOption, (item, key) =>{
        if(item.require) {
            isAllRequireEmpty = isAllRequireEmpty && _.isEmpty(form[key])
        }
    })
    
    const tempError = initError(form, validationOption);

    const hasError = isAllRequireEmpty || _.some(tempError, item => {
        return _.isEmpty(item) === false
    })

    return hasError;
}

export function mkError(form, validationOption) {
    const error = _.mapValues(form, (o, key) => {
        return checkValidation(o, validationOption[key]);
    })
    return error;
}

