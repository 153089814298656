import React from 'react';
import classnames from 'classnames/bind';
import styles from './GGForm.module.scss';
import {Input} from 'react-c2c-ui';


const cx = classnames.bind(styles);

const GGForm = ({
    forms,
    handleChange,
}) => {
    return (
        <div className={cx('form')}>
            <h1>기본 정보</h1>
            <div className={cx("basic")}>
                <Input
                    label="Grader"
                    name='grader'
                    value={forms.grader}
                    onChange={handleChange}
                    required
                    placeholder='Grader(required)'
                    width='18.75rem'
                />
                <Input
                    label="Grading Date"
                    name='gradingDate'
                    value={forms.gradingDate}
                    onChange={handleChange}
                    required
                    type="date"
                    placeholder='Grading Date(required)'
                    width='18.75rem'
                />
            </div>
            <h1>생두 측정</h1>
            <div className={cx('row')}>

                <Input
                    symbol="g/l"
                    name='density'
                    label='density'
                    value={forms.density}
                    onChange={handleChange}
                    required
                    type='number'
                    placeholder='Enter density'
                    step={1}
                    min={600}
                    max={999}
                />
                <Input
                    symbol="aw"
                    name='waterActivity'
                    label='waterActivity'
                    value={forms.waterActivity}
                    onChange={handleChange}
                    required
                    type='number'
                    placeholder='Enter waterActivity'
                    step={0.001}
                    min={0}
                    max={1}
                />
                <Input
                    symbol="%"
                    name='moisture'
                    label='moisture'
                    value={forms.moisture}
                    onChange={handleChange}
                    required
                    type='number'
                    placeholder='Enter moisture'
                    step={0.1}
                    min={0}
                    max={100}
                />
            </div>

            <h1>Defects</h1>
            <table>
                <thead>
                    <tr>
                        <td>Full Black</td>
                        <td>Full Sour</td>
                        <td>Dried Cherry AndPod</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><input type="number" min="0" value={forms.fullBlack} name="fullBlack" onChange={handleChange}/></td>
                        <td><input type="number" min="0" value={forms.fullSour} name="fullSour" onChange={handleChange}/></td>
                        <td><input type="number" min="0" value={forms.driedCherryAndPod} name="driedCherryAndPod" onChange={handleChange}/></td>
                    </tr>
                </tbody>
            </table>
            <table>
                <thead>
                    <tr>
                        <td>Fungus Damaged</td>
                        <td>Severe Insect Damaged</td>
                        <td>Foreign Matter</td>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td><input type="number" min="0" value={forms.fungusDamaged} name="fungusDamaged" onChange={handleChange}/></td>
                        <td><input type="number" min="0" value={forms.severeInsectDamaged} name="severeInsectDamaged" onChange={handleChange}/></td>
                        <td><input type="number" min="0" value={forms.foreignMatter} name="foreignMatter" onChange={handleChange}/></td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead>
                    <tr>
                        <td>Partial Black</td>
                        <td>Partial Sour</td>
                        <td>Parchment</td>
                        <td>Floater</td>
                        <td>Immature&Unripe</td>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td><input type="number" min="0" value={forms.partialBlack} name="partialBlack" onChange={handleChange}/></td>
                        <td><input type="number" min="0" value={forms.partialSour} name="partialSour" onChange={handleChange}/></td>
                        <td><input type="number" min="0" value={forms.parchment} name="parchment" onChange={handleChange}/></td>
                        <td><input type="number" min="0" value={forms.floater} name="floater" onChange={handleChange}/></td>
                        <td><input type="number" min="0" value={forms.immatureAndUnripe} name="immatureAndUnripe" onChange={handleChange}/></td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead>
                    <tr>
                        <td>Withered</td>
                        <td>Shell</td>
                        <td>Broken&Chipped&Cut</td>
                        <td>Hull&Husk</td>
                        <td>Slight Insect Damaged</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><input type="number" min="0" value={forms.withered} name="withered" onChange={handleChange}/></td>
                        <td><input type="number" min="0" value={forms.shell} name="shell" onChange={handleChange}/></td>
                        <td><input type="number" min="0" value={forms.brokenAndChippedAndCut} name="brokenAndChippedAndCut" onChange={handleChange}/></td>
                        <td><input type="number" min="0" value={forms.hullAndHusk} name="hullAndHusk" onChange={handleChange}/></td>
                        <td><input type="number" min="0" value={forms.slightInsectDamaged} name="slightInsectDamaged" onChange={handleChange}/></td>
                    </tr>
                </tbody>
            </table>

            <h1 style={{marginTop: '2.5rem'}}>Weight</h1>
            <div className={cx('row')}>

                <Input
                    symbol="gram"
                    name='quantityForRoasting'
                    label='로스팅에 사용할 샘플 양'
                    value={forms.quantityForRoasting}
                    onChange={handleChange}
                    required
                    type='number'
                    step={1}
                    min={50}
                />
                <Input
                    symbol="gram"
                    name='ggWeight'
                    label='Green Grading에 사용한 샘플 양'
                    value={forms.ggWeight}
                    onChange={handleChange}
                    required
                    type='number'
                    step={1}
                    min={0}
                />
                <Input
                    name='defectsWeight'
                    label='Defects Weight'
                    value={forms.defectsWeight}
                    onChange={handleChange}
                    required
                    type={'number'}
                    placeholder='0'
                    step={1}
                    min={0}
                    symbol="gram"
                />
            </div>
        </div>
        
    )
}

export default GGForm;