export const links = {
    coffeeList: '/coffees',
    coffeeDetail: '/coffees/:id/',
    samplePackDetail: '/samplePacks/:samplePackId',
    evaluateGreenGrading: '/samplePacks/:samplePackId/evaluate/green-grading/:mode',
    evaluateCoffee: '/coffees/:id/evaluate/',
    evaluateCupping: '/samplePacks/:samplePackId/evaluate/cupping/:mode',
    addSamples: '/coffees/:id/samples/add/',
    sampleTradeList: '/sample/trades/',
    sampleTradeDetail: '/sample/trades/:id',
    setting: '/setting'
    //user

    //trade

    //sample

    //sample-trade
}