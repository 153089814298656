import React, { useEffect } from 'react'
import styled, { css } from 'styled-components';
import { rem, utils, flex, palette, media, InfoGroup, Input, Button, ButtonGroup } from 'react-c2c-ui';
import { links } from 'lib/links';
import { RouteComponentProps } from 'react-router';
import useRequest from 'lib/hooks/useRequest';
import HeaderTemplate from 'components/_templates/HeaderTemplate';
import {bodyStyle} from 'containers/CoffeeDetail/CoffeeDetail';
import nameGenerator from 'lib/nameGenerator';
import useInputs from 'lib/hooks/useInputs';
import cAxios from 'lib/axiosInstance';

interface OwnProps {

}
type Props = OwnProps&RouteComponentProps<{id:string}>;

const SampleTradeDetail: React.SFC<Props> = ({match}) => {
    const [
        onRequestGetSampleTradeDetail,
        getSampleTradeDetailResult,
        getSampleTradeDetailLoading,
        getSampleTradeDetailError
    ] = useRequest<any>(()=>
        cAxios.get(
            `/sample/mgmt/trades/${match.params.id}`
        )
    );
    const getSampleTradeDetail = async () => {
        await onRequestGetSampleTradeDetail();
    }

    useEffect(() => {
        getSampleTradeDetail();
    }, [])

    const [
        onRequestPostTracking,
        postTrackingResult,
        postTrackingLoading,
        postTrackingError
    ] = useRequest<any>((data)=>
        cAxios.post(
            `/sample/mgmt/trades/${match.params.id}`, data
        )
    );
    const submit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        await onRequestPostTracking({
            sentDate: forms.sentDate,
            courier: forms.courier,
            trackingNumber: forms.trackingNumber
        });
    }

    const [forms, onChange, onSelect, dispatchData] = useInputs({
        courier: '',
        trackingNumber: '',
        sentDate: new Date().toJSON().slice(0,10)
    })
    if(getSampleTradeDetailResult) {
        const {
            samples=[], user={}, payment={}, address={}, id, status, courier, trackingNumber, sentAt
        } = getSampleTradeDetailResult;
        const {
            email, buyer={}
        } = user;
        const {
            city, country, state, street, type, zip
        } = address;
        return (
            <SampleTradeDetailBlock>
                <HeaderTemplate
                    backText={"Sample Trade List 페이지로 돌아갑니다."}
                    headerText={`Sample Trade ID: ${match.params.id}`}
                    backTo={links.sampleTradeList}
                />
                <BuyerAndPayment>
                    <h2>구매자 정보 | 결제 정보</h2>

                    <div className="contents">
                        <article>
                            <h3>구매자 정보</h3>
                            <InfoGroup 
                                infos={[
                                    {
                                        label: "구매자 email",
                                        value: email
                                    },
                                    {
                                        label: "buyer.companyName",
                                        value: buyer.companyName
                                    },
                                    {
                                        label: "배송 주소",
                                        value: nameGenerator.generateAddressText(country, state, city, street, zip)
                                    },
                                    {
                                        label: "주문 시각",
                                        value: payment.createdAt.slice(0,10)
                                    },
                                ]}
                            />
                        </article>

                        <article>
                            <h3>결제 정보</h3>
                            <InfoGroup 
                                infos={[
                                    {
                                        label: "Payment Id",
                                        value: payment.orderId
                                    },
                                    {
                                        label: "Payment time",
                                        value: payment.createdAt.slice(0,10)
                                    },
                                    {
                                        label: "Total price",
                                        value: payment.totalPrice
                                    }
                                ]}
                            />
                        </article>
                    </div>
                </BuyerAndPayment>
                
                <Samples>
                    <h2>샘플 정보</h2>
                    <ul>
                        <>
                            {samples.map((item:any) => {

                                const {
                                    coffeeId,
                                    samplePackId,
                                    sampleTradeId,
                                    isPaid,
                                    price,
                                    amount,
                                    purpose,
                                    note,
                                    status,
                                    createdAt,
                                    updatedAt,
                                    samplePack
                                } = item;
                                const sample = `${nameGenerator.generateShortSampleName(
                                    samplePack.id,
                                    samplePack.coffee.farm.country,
                                    samplePack.coffee.farm.region,
                                    samplePack.coffee.processing)
                                }`;
                                return (
                                    <li key={item.id}>{sample}</li>
                                )
                            })}
                        </>
                    </ul>
                </Samples>
                <section>
                    {(status === 'DELIVERY' || status === 'COMPLETE')
                        ?
                        <>
                            <h2>배송 정보 입력 완료. 배송 정보</h2>
                            <InfoGroup
                                infos={[
                                    {label:'courier', value:courier},
                                    {label:'trackingNumber', value:trackingNumber},
                                    {label:'sentAt', value:sentAt.slice(0,10)}
                                ]}
                            />
                        </>

                        :
                        <>
                            <h2>배송 정보 입력</h2>
                            <DeliveryForm onSubmit={submit}>
                                <div className="row">
                                    <Input
                                        name='courier'
                                        value={forms.courier}
                                        onChange={onChange}
                                        required
                                        label='Courier'
                                    />
                                    <Input
                                        name='trackingNumber'
                                        value={forms.trackingNumber}
                                        onChange={onChange}
                                        required
                                        label='Tracking Number'
                                    />
                                    <Input
                                        name='sentDate'
                                        value={forms.sentDate}
                                        onChange={onChange}
                                        required
                                        label='sentDate'
                                        type="date"
                                    />
                                </div>
                                <ButtonGroup rightAlign>
                                    <Button type="submit" loading={postTrackingLoading}>Submit</Button>
                                </ButtonGroup>
                            </DeliveryForm>
                        </>

                        
                    }
                </section>
            </SampleTradeDetailBlock>
        )
    } else {
        if(getSampleTradeDetailLoading) {
            return (
                <p>...loading</p>
            )
        } else if(getSampleTradeDetailError) {
            return (
                <h1>{getSampleTradeDetailError}</h1>
            )
        } else {
            return (
                <p>Got unexpected error.</p>
            )
        }
    }
}

const SampleTradeDetailBlock = styled.section`
    ${utils.containerBox};
    >section {
        >h2 {
            margin-bottom: ${rem(20)};
        }
    }
`;

const BuyerAndPayment = styled.section`
    margin-top: ${rem(40)};

    .contents {
        ${bodyStyle};
    }
`;

const Samples = styled.section`

    >ul {

        >li {

        }
    }

`;

const DeliveryForm = styled.form`
    >.row {
        display: flex;
        margin-bottom: ${rem(20)};
        >div + div {
            margin-left: ${rem(10)};
        }
    }
`;

const DeliveryInfo = styled.section`
`;

export default SampleTradeDetail;