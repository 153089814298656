import { createAction, handleActions } from 'redux-actions';

const OPEN_SELECTOR = "trigger/OPEN_SELECTOR";
const CLOSE_SELECTOR = "trigger/CLOSE_SELECTOR";
export const openSelector = createAction(OPEN_SELECTOR);
export const closeSelector = createAction(CLOSE_SELECTOR);

const SET_TRIGGER_NAME = "trigger/SET_TRIGGER_NAME";
export const setTriggerName = createAction(SET_TRIGGER_NAME);
const INIT_TRIGGER_NAME = "trigger/INIT_TRIGGER_NAME";
export const initTriggerName = createAction(INIT_TRIGGER_NAME);

const OPEN_POPUP = "trigger/OPEN_POPUP";
const CLOSE_POPUP = "trigger/CLOSE_POPUP";
export const openPopup = createAction(OPEN_POPUP);
export const closePopup = createAction(CLOSE_POPUP);

const initialState = {
    selector: {
        name: '',
        isOpen: false
    },

    trigger: {
        name: '',
        isOpen: false
    },

    popup: {
        name: '',
        isOpen: false
    },
}

export default handleActions({
    [OPEN_SELECTOR]: (state, action) => {
        
        return {
            ...state,
            selector: {
                ...state.selector,
                name: action.payload.name,
                isOpen: true
            }
        }
    },

    [CLOSE_SELECTOR]: (state, action) => {
        return {
            ...state,
            selector: {
                ...initialState.selector
            }
        }
    },

    [SET_TRIGGER_NAME] : (state, action) => {
        return  {
            ...state,
            trigger: {
                ...state.trigger,
                name: action.payload
            }
        }
    },

    [INIT_TRIGGER_NAME] : (state, action) => {
        return {
            ...state,
            trigger: {...initialState.trigger}
        }
    },

    [OPEN_POPUP]: (state, action) => {
        return {
            ...state,
            popup: {
                ...state.popup,
                name: action.payload,
                isOpen: true
            }
        }
    },

    [CLOSE_POPUP]: (state, action) => {
        return {
            ...state,
            popup: {
                ...initialState.popup
            }
        }
    },
}, initialState)