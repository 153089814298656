import React, { Component } from 'react';
import Input from 'components/_inputs/Input';
import { checkValidation } from 'lib/errorChecker';

import classnames from 'classnames/bind';
import styles from './AddSamples.module.scss';
import Axios from 'axios';

import { convertCountryCodeToName } from 'lib/nameGenerator';

const cx = classnames.bind(styles);

const validationOption = {
    receiveTime:{
        require:true,
        type:'string'
    },
    totalWeight:{
        require:true,
        type:'number'
    },
    pcs:{
        require:true,
        type:'number'
    },
    oneWeight:{
        require:true,
        type:'number'
    },
    onePrice:{
        require:true,
        type:'number'
    },
}
class AddSamples extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            form:{
                totalWeight:2000,
                pcs:Math.floor(2000/350)-1,
                oneWeight:350,
                onePrice:100
            },
            error:{},
            hasError:true,
            coffeeDetail:{}
        }

        this.handleChange = this.handleChange.bind(this);
        this.submit = this.submit.bind(this);
    }
    componentDidMount() {
        Axios.get(
            `http://localhost:5002/api/coffee/mgmt/${this.props.match.params.id}`,
            {headers:{'x-access-token':window.localStorage.getItem('signInToken')}}
        )
        .then(res => {
            console.log('res.data :', res.data);
            this.setState({
                coffeeDetail:res.data.coffeeDetail
            });
        })
        .catch(e => {
            console.log('e.response :', e.response);
        })
    }
    
    handleChange(name, value) {
        let autoSetPcs = 0; 

        let form={};
        if( name==="totalWeight" && this.state.form.oneWeight) {
            if(value>0) {
                autoSetPcs = Math.floor(Number(value)/Number(this.state.form.oneWeight))-1;
                console.log('autoSetPcs :', autoSetPcs);
            }
            form= {
                ...this.state.form,
                [name]:value,
                pcs:autoSetPcs
            }
        } else if(name==="oneWeight" && this.state.form.totalWeight) {
            if(value>0) {
                autoSetPcs = Math.floor(Number(this.state.form.totalWeight/Number(value)))-1;
                console.log('autoSetPcs :', autoSetPcs);
            }
            form= {
                ...this.state.form,
                [name]:value,
                pcs:autoSetPcs
            }
        } else {
            form= {
                ...this.state.form,
                [name]:value
            }
        }
        this.setState({
            form,
            error:{
                ...this.state.error,
                [name]:checkValidation(value, validationOption[name])
            }
        });
    }

    submit() {
        console.log('submit');
        Axios.post(
            'http://localhost:5002/api/sample/mgmt/add',
            {
                coffeeId: this.props.match.params.id,
                sampleData: this.state.form
            },
            {headers:{"x-access-token":window.localStorage.getItem("signInToken")}}
        )
        .then(res=> {
            console.log('request success');
            console.log('res.data :', res.data);
            this.props.history.push(`/coffees/${this.props.match.params.id}`)
        })
        .catch(e => {
            console.log('fail, e.response :', e.response);
        })
    }
    
    render() {
        const {
            form, error
        } = this.state;
        const {handleChange, submit} = this;
        const {
            altitude, annualRainfall, bagSize,
            certifications, createdAt, evaluationId,
            farmId, grade, greenGrading,
            greenGradingId, harvest, id,
            image, lotNumber, moq,
            origin, packaging, price,
            processing, region, screenSize,
            soil, status, stock,
            story, temperature,
            updatedAt, userId
        } = this.state.coffeeDetail;
        let user={};
        if(this.state.coffeeDetail.user) {
            user=this.state.coffeeDetail.user;
        }
        let name= "";
        let sellerId;
        if(user) {
            name=user.name;
            sellerId=user.sellerId;
        }
        let seller ={};
        if(user.sellerId) {
            seller = user.seller;
        }
        const  {
            companyName
        } = seller;

        let varieties=[];
        if(this.state.coffeeDetail.varieties) {
            varieties = this.state.coffeeDetail.varieties;
        }
        varieties = varieties.map(item => {
            return item.name
        })
        return (
            <div className={cx('form_summary-template')}>
                <div className={cx('form')}>
                    <h1>샘플 등록 / Coffee id : {this.props.match.params.id}</h1>
                    <Input
                        name='receiveTime'
                        label='샘플 받은 시간'
                        value={form.receiveTime}
                        error={error.receiveTime}
                        onChange={handleChange} 
                        require={validationOption.receiveTime.require}
                        type={validationOption.receiveTime.type}
                        placeholder='yy.mm.dd - hh:mm'
                    />

                    <div className={cx('row-cdr-2')}>
                        <Input
                            name='totalWeight'
                            label='샘플 총 수량(gram)'
                            value={form.totalWeight}
                            error={error.totalWeight}
                            onChange={handleChange} 
                            require={validationOption.totalWeight.require}
                            type={validationOption.totalWeight.type}
                            placeholder='Enter 샘플 총 수량(gram)'
                        />
                        <Input
                            name='pcs'
                            label='샘플 갯수'
                            value={form.pcs}
                            error={error.pcs}
                            onChange={handleChange} 
                            require={validationOption.pcs.require}
                            type={validationOption.pcs.type}
                            placeholder='Enter 샘플 갯수'
                        />
                    </div>

                    <div className={cx('row-cdr-2')}>
                        <Input
                            name='oneWeight'
                            label='샘플 당 무게'
                            value={form.oneWeight}
                            error={error.oneWeight}
                            onChange={handleChange} 
                            require={validationOption.oneWeight.require}
                            type={validationOption.oneWeight.type}
                            placeholder='Enter 샘플 당 무게(gram)'
                        />
                        <Input
                            name='onePrice'
                            label='샘플 가격'
                            value={form.onePrice}
                            error={error.onePrice}
                            onChange={handleChange} 
                            require={validationOption.onePrice.require}
                            type={validationOption.onePrice.type}
                            placeholder='Enter 샘플 가격(USD)'
                        />
                    </div>
                </div>
                <div className={cx('summary')}>
                    <h3>Coffee Info</h3>
                    <p>seller: {name}({companyName})</p>
                    <p>coffee info: [<span title={`origin`}>{origin&&convertCountryCodeToName(origin)}</span>-<span title={`region`}>{region}</span>] <span title={`processing`}>{processing}</span> (<span title={`varieties`}>{varieties&&varieties.join(", ")}</span>)</p>
                    <div className={cx('divider')}/>
                    <h3>Summary</h3>
                    <p>Received <b>{form.totalWeight?form.totalWeight:"-"}g</b> samples at <b>{form.receiveTime?form.receiveTime:"-"}</b></p>
                    <p><b>{form.oneWeight?form.oneWeight:"-"}g</b>sample(<b>{form.onePrice?form.onePrice:"-"}USD</b>) x <b>{form.pcs?form.pcs:"-"}</b>pcs</p>
                    <div className={cx('row-cdr-2')}>
                        <button className={cx('cancel')}>Cancel</button>
                        <button className={cx('submit')} type="submit" onClick={submit}>Submit</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddSamples;