import React from 'react';

// style
import classnames from 'classnames/bind';
import styles from './Validation.scss';
const cx = classnames.bind(styles);

const Validation = ({error}) => {
    return (
        <div className={cx('validation-wrapper')}>
            {error&&<div className={cx('warning-text')}>{error}</div>}
        </div>
    );
};

export default Validation;