import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components';
import { rem, utils, flex, palette, media, ButtonGroup, Button, Modal, Input, InfoGroup } from 'react-c2c-ui';
import useRequest from 'lib/hooks/useRequest';
import HeaderTemplate from 'components/_templates/HeaderTemplate';
import { links } from 'lib/links';
import SamplePackList from './SamplePackList';
import { RouteComponentProps, withRouter } from 'react-router';
import { infoBoxCss } from 'containers/SamplePackDetail';
import SamplePackSampleList from 'containers/samplePack/SamplePackSampleList';
import CuppingInfo from 'containers/samplePack/CuppingInfo';
import GreenGradingInfo from 'containers/samplePack/GreenGradingInfo';
import { mkSellerCoffeeStatus } from 'lib/status';
import cAxios from 'lib/axiosInstance';

interface OwnProps {

}
type Props = OwnProps & RouteComponentProps<{ id: string }, {}, {page?: number}>;

const CoffeeDetail: React.SFC<Props> = ({ history, match, location, staticContext }) => {
    const coffeeId = Number(match.params.id);


    //# GET_COFFEE_DETAIL
    const [
        onRequestGetCoffeeDetail,
        getCoffeeDetailResult,
        getCoffeeDetailLoading,
        getCoffeeDetailError
    ] = useRequest<any>(() =>
        cAxios.get(
            `/coffee/mgmt/${coffeeId}`
        )
    );
    const [
        onRequestGetSamplePack,
        getSamplePackResult,
        getSamplePackLoading,
        getSamplePackError
    ] = useRequest<any>((samplePackId) =>
        cAxios.get(
            `/sample/mgmt/samplePacks/${samplePackId}`
        )
    );

    const [samplePackStatus, setSamplePackStatus] = useState('');
    const getCoffeeDetail = async () => {
        const data = await onRequestGetCoffeeDetail();
        const samplePackData = await onRequestGetSamplePack(data.coffeeDetail.selectedSamplePackId);
        samplePackData.samplePackOne && setSamplePackStatus(mkSellerCoffeeStatus(data.coffeeDetail.isStoppedSelling, data.coffeeDetail.samplePacks, data.coffeeDetail.selectedSamplePackId));
        
    }

    useEffect(() => {
        getCoffeeDetail();
    }, [])

    if (getCoffeeDetailLoading) return <p>loading...</p>
    if (getCoffeeDetailResult) {
        console.log('getCoffeeDetailResult', getCoffeeDetailResult)
        const {
            id,
            selectedSamplePackId,
            userId,
            evaluationId,
            farmId,
            status,
            deprecated,
            processing,
            grade,
            harvest,
            screenSize,
            image,
            story,
            packaging,
            bagSize,
            price,
            moq,
            stock,
            createdAt,
            updatedAt,
            farm = {},
            varieties = [],
            certifications = [],
            samplePacks = [],
            user = {},
        } = getCoffeeDetailResult.coffeeDetail;

        const {
            country,
            region,
            altitude,
            temperature,
            soil,
            annualRainfall
        } = farm;
        const farmName = farm.name;

        const {
            name,
            email,
            seller = {}
        } = user;

        const {
            companyName,
            companyEmail,
            tin,
            website,
            cellPhoneNumber,
            managerPhoneNumber,
            logoImage,
            backgroundImage,
            managerName,
            managerEmail,
            managerCellPhoneNumber,
            bankName,
            bankBranch,
            bankAccount,
            recipient,
            swiftCode,
            bankAddress
        } = seller;
        return (
            <CoffeeDetailBlock>
                <HeaderTemplate
                    backText={"Coffee list 페이지로 돌아갑니다."}
                    headerText={`COFFEE ID: ${coffeeId}`}
                    backTo={location.state?links.coffeeList+`?page=${location.state.page}`:links.coffeeList+`?page=1`}
                />
                <SamplePackList
                    coffeeId={coffeeId}
                    selectedSamplePackId={getCoffeeDetailResult.coffeeDetail.selectedSamplePackId}
                />

                <SellerAndGeoGraphyInfo>
                    <div className="title">
                        <h2>판매자 정보 / 커피 지역 정보(Farm정보)</h2>

                        {/* //TEMP */}
                        {/* <p>커피 지역 정보 수정</p> */}
                    </div>

                    <div className="contents">
                        <article>
                            <h3>판매자 정보</h3>
                            <InfoGroup
                                infos={[
                                    {
                                        label: "Email",
                                        value: email
                                    },
                                    {
                                        label: "Name",
                                        value: name
                                    },
                                    {
                                        label: "Company name",
                                        value: companyName
                                    },
                                    {
                                        label: "Tellephone",
                                        value: cellPhoneNumber
                                    }
                                ]}
                            />
                        </article>
                        <article>
                            <h3>커피 지역 정보</h3>
                            <InfoGroup
                                infos={[
                                    {
                                        label: "Origin",
                                        value: country
                                    },
                                    {
                                        label: "Region",
                                        value: region
                                    },
                                    {
                                        label: "Altitude",
                                        value: altitude
                                    },
                                    {
                                        label: "Temperature",
                                        value: temperature
                                    },
                                    {
                                        label: "Soil",
                                        value: soil
                                    },
                                    {
                                        label: "Annual rainfall",
                                        value: annualRainfall
                                    }
                                ]}
                            />
                        </article>
                    </div>
                </SellerAndGeoGraphyInfo>

                <CoffeeAndSellingInfo>
                    <div className="title">
                        <h2>커피 정보 / 판매 정보</h2>
                        
                        {/* //TEMP */}
                        {/* <p>수정</p> */}
                    </div>

                    <div className="contents">
                        <article>
                            <h3>커피 정보</h3>
                            <InfoGroup
                                infos={[
                                    {
                                        label: "Processing",
                                        value: processing
                                    },
                                    {
                                        label: "Grade",
                                        value: grade
                                    },
                                    {
                                        label: "Harvest",
                                        value: harvest
                                    },
                                    {
                                        label: "Screen size",
                                        value: screenSize
                                    },
                                    {
                                        label: "Certifications",
                                        value: certifications.map((item: any) => item.certification).join(', ')
                                    },
                                    {
                                        label: "Varieties",
                                        value: varieties.map((item: any) => item.variety).join(', ')
                                    }
                                ]}
                            />
                        </article>
                        <article>
                            <h3>판매 정보</h3>
                            <InfoGroup
                                infos={[
                                    {
                                        label: "Price",
                                        value: price
                                    },
                                    {
                                        label: "Stock",
                                        value: stock
                                    },
                                    {
                                        label: "MOQ",
                                        value: moq
                                    },
                                    {
                                        label: "Bag size",
                                        value: bagSize
                                    },
                                    {
                                        label: "Packaging",
                                        value: packaging
                                    }
                                ]}
                            />
                        </article>
                    </div>
                </CoffeeAndSellingInfo>

                {getSamplePackResult
                    ?
                    <React.Fragment>
                        {(samplePackStatus === "STOP_SELLING" || samplePackStatus ==="EVALUATED")
                            ?
                            <React.Fragment>
                                <InfoBox>
                                    {getSamplePackResult.samplePackOne.greenGrading ?
                                        <div className="col">
                                            <h2>Green grading 정보</h2>
                                            <GreenGradingInfo greenGrading={getSamplePackResult.samplePackOne.greenGrading} />
                                        </div> : null
                                    }
                                </InfoBox>

                                <InfoBox>
                                    {getSamplePackResult.samplePackOne.cuppings.length ?
                                        <div className="col">
                                            <h2>Cupping 정보</h2>
                                            <CuppingInfo cuppings={getSamplePackResult.samplePackOne.cuppings} flavors={getSamplePackResult.samplePackOne.flavors} />
                                        </div> : null
                                    }
                                </InfoBox>

                                <InfoBox>
                                    {getSamplePackResult.samplePackOne.samples.length
                                        ?
                                        <div className="col">
                                            <h2>샘플 정보</h2>
                                            <SamplePackSampleList samples={getSamplePackResult.samplePackOne.samples} />

                                        </div> : null
                                    }
                                </InfoBox>
                            </React.Fragment>

                            :
                            <section>
                                <p>samplePack정보가 없거나 품질평가가 완료되지 않았습니다.</p>
                            </section>
                        }

                    </React.Fragment>

                    :
                    <p>samplePack loading...</p>
                }
            </CoffeeDetailBlock>
        )
    } else {
        return null;
    }
}



const CoffeeDetailBlock = styled.section`
    ${utils.containerBox};
    max-width: ${rem(1080)};
`;

export const titleStyle = css`
    ${flex.row};
    ${flex.jc.spaceB};
    ${flex.ai.center};
    margin-bottom: ${rem(20)};

    >p {
        font-size: ${rem(18)};
        text-decoration: underline;
        color: #219653;
        cursor: pointer;
    }
`;

export const bodyStyle = css`
    ${flex.row};
    >article {
        flex: 1 1;
    }
`;

const SellerAndGeoGraphyInfo = styled.section`
    margin-top: ${rem(40)};
    >.title {
        ${titleStyle};
    }

    >.contents {
        ${bodyStyle};
    }
`;

const CoffeeAndSellingInfo = styled.section`
    >.title {
        ${titleStyle};
    }

    >.contents {
        ${bodyStyle};
    }
`;

const InfoBox = styled.section`
    ${infoBoxCss};
`;

export default withRouter(CoffeeDetail);