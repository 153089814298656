import React, { Component, useState, useEffect } from 'react';
import _ from 'lodash';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import useRequest from 'lib/hooks/useRequest';
import styled from 'styled-components';
import { mkC2CScore } from 'lib/scoreMaker';
import cAxios from 'lib/axiosInstance';
interface OwnProps {
    cuppingScore: string;
}
type Props = OwnProps&RouteComponentProps<{samplePackId:string, mode:string}>;

const C2CScore: React.SFC<Props> = ({history, match, cuppingScore}) => {
    //# GET_SAMPLE_PACK
    const [
        onRequestGetSamplePack,
        getSamplePackResult,
        getSamplePackLoading,
        getSamplePackError
    ] = useRequest<any>(()=>
        cAxios.get(
            `/sample/mgmt/samplePacks/${match.params.samplePackId}`
        )
    );

    const [c2cScoreDetail, setC2cScoreDetail] = useState({
        c2cScore: 0,
        costs: {
            cuppingCost:0,
            harvestCost:0,
            densityCost:0,
            fullDefectsCost:0,
            screenSizeCofe: '0'
        }
    });
    const getSamplePack = async () => {
        const data = await onRequestGetSamplePack();
        const greenGrading = data.samplePackOne.greenGrading;
        const harvest = data.samplePackOne.coffee.harvest;
        const screenSize = data.samplePackOne.coffee.screenSize;

        setC2cScoreDetail(mkC2CScore(harvest, greenGrading, screenSize, cuppingScore));
    }

    useEffect(() => {
        getSamplePack()
    }, [])

    useEffect(() => {
        if(getSamplePackResult) {
            const greenGrading = getSamplePackResult.samplePackOne.greenGrading;
            const harvest = getSamplePackResult.samplePackOne.coffee.harvest;
            const screenSize = getSamplePackResult.samplePackOne.coffee.screenSize;
            setC2cScoreDetail(mkC2CScore(harvest, greenGrading, screenSize, cuppingScore));
        }
    }, [cuppingScore])

    if(getSamplePackResult) {
        return (
            <C2CScoreBlock>
                <h3>Cupping Score : {cuppingScore}</h3>
                <h3>C2C Score : {c2cScoreDetail.c2cScore}</h3>
                <p>c2c score detail(calculated)</p>
                <ul>
                    <li>cuppingScore : {c2cScoreDetail.costs.cuppingCost}(30%)</li>
                    <li>term of now from harvest start : {c2cScoreDetail.costs.harvestCost}(10%)</li>
                    <li>density : {c2cScoreDetail.costs.densityCost}(10%)</li>
                    <li>Number of full Defects : {c2cScoreDetail.costs.fullDefectsCost}(25%)</li>
                    <li>screen size difference : {c2cScoreDetail.costs.screenSizeCofe}(25%)</li>
                </ul>
            </C2CScoreBlock>
        )
    } else {
        return (
            <h5>loading...</h5>
        )
    }
}

const C2CScoreBlock = styled.div`
    
`;

export default withRouter(C2CScore);