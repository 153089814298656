import React, { useState } from 'react'
import styled, { css } from 'styled-components';
import { rem, utils, flex, palette, media, Popup, Button } from 'react-c2c-ui';
import { RouteComponentProps, withRouter } from 'react-router';
import { links } from 'lib/links';
import { MdViewList, MdDonutSmall, MdDonutLarge } from 'react-icons/md';
import Logo from 'images/logos/white_logo.png';

interface OwnProps {

}
type Props = OwnProps&RouteComponentProps;

const Nav: React.SFC<Props> = ({history}) => {
    const [target, setTarget] = useState("");
    const logout = () => {
        window.localStorage.removeItem('signInToken');
        setTarget("");
        history.push('/signIn');
    }
    return (
        <NavBlock>
            <img src={Logo} alt="" />

            <div className="menus">
                {/* <div className="menu-item" onClick={() => { history.push(links.coffeeList + `?page=1`) }}>
                    <MdViewList />
                    <p>Coffee List</p>
                </div>

                <div className="menu-item" onClick={() => { history.push(links.sampleTradeList) }}>
                    <MdViewList />
                    <p>Sample Trade</p>
                </div> */}

                <div className="menu-item" onClick={() => { history.push('/evaluation-report') }}>
                    <MdDonutSmall />
                    <p>Arabica Report</p>
                </div>

                <div className="menu-item" onClick={() => { history.push('/evaluation-report/robusta') }}>
                    <MdDonutLarge />
                    <p>Robusta Report</p>
                </div>
            </div>
            <div className="right">
                <Popup
                    name="user"
                    setTarget={(target:string) => {
                        console.log('click trigger')
                        setTarget(target)
                    }
                    }
                    trigger={
                        <Button1 theme="tertiary">user</Button1>
                    }
                    contents={
                        <Contents>
                            <li
                                onClick={() => {
                                    setTarget("");
                                    history.push(links.setting);
                                }}
                            >setting</li>
                            <li onClick={logout}>logout</li>
                        </Contents>
                    }
                    target={target}
                    contentsPosition='right'
                />


            </div>
        </NavBlock>
    )
}

const NavBlock = styled.div`
    width: 100%;
    height: ${rem(60)};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 99;
    background: ${palette.blue500};
    padding: ${rem(20)};
    >img {
        height: ${rem(30)};
        margin-right: ${rem(50)};
    }

    .menus {
        display: flex;
        align-items: center;
        width: 100%;
        * {
            color: white;
            font-size: ${rem(20)};
        }

        .menu-item {
            display: flex;
            align-items: center;
            margin-right: ${rem(20)};
            opacity: 0.7;
            cursor: pointer;
            &:hover {
                opacity: 1;
            }
            
            >svg {
                margin-right: ${rem(10)};
            }
        }
    }
    >.right {
        display: flex;
        >a {
            text-decoration: none;
            color: rgb(230, 230, 230);
            text-align: right;
            width: 100%;

            &:hover {
                color: white;
            }
        }

        >a + a {
            margin-left: ${rem(10)};
        }
    }
`;

const Button1 = styled(Button)`
    background: none;
    color: white;
    &:hover {
        color: ${palette.blue500};
        background: white;

    }
`;
const Contents = styled.ul`
    margin: 0;
    width: ${rem(120)};
    padding: 0;
    list-style: none;
    >li {
        padding: ${rem(10)};
        &:hover {
            background: ${palette.gray400};
        }
    }
`;

export default withRouter(Nav);