import styled, { createGlobalStyle } from 'styled-components';
import { rem, utils, flex, palette, media } from 'react-c2c-ui';
export const GlobalStyle = createGlobalStyle`
	body {
		.single-ellipsis {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        section {
            
            h1 {

            }

            h2 {
                font-size: ${rem(24)};
                font-weight: 700;
            }

            article {
                h3 {
                    color: ${palette.blue600};
                    font-size: ${rem(16)};
                    margin-bottom: ${rem(10)};
                }
            }
        }

        section + section {
            margin-top: ${rem(40)};
            padding-top: ${rem(40)};
            border-top: 1px solid ${palette.gray400};
        }

        article + article {
            margin-top: ${rem(20)};
        }
	}
`;