export const mkSellerCoffeeStatus = (isStoppedSelling: boolean, samplePacks:any[], selectedSamplePackId:number|null) => {
    let hasCompletedOne = false;
    samplePacks.map(item => {
        if(item.greenGradingId && item.cuppings.length) {
            hasCompletedOne = true;
        }
    })
    if(samplePacks.length) {
        if(selectedSamplePackId && hasCompletedOne) {
            if(isStoppedSelling) {
                return 'STOP_SELLING';
            }
            return 'EVALUATED';
        }
        return 'SAMPLE_PACK_READY';
    } else {
        return 'NO_SAMPLE_PACK';
    }
}