import React from 'react'
import styled, { css } from 'styled-components';
import { rem, utils, flex, palette, media } from 'react-c2c-ui';
import { TableBox } from './CuppingInfo';

interface OwnProps {
    greenGrading: {
        grader: string;
        gradingDate: Date;
        measuredQuantity: number;
        density:number;
        waterActivity:number;
        moisture:number;
        defectsWeight: number;

        fullBlack:number;
        fullSour:number;
        driedCherryAndPod:number;
        fungusDamaged:number;
        severeInsectDamaged:number;
        foreignMatter:number;
        partialBlack:number;
        partialSour:number;
        parchment:number;
        floater:number;
        immatureAndUnripe:number;
        withered:number;
        shell:number;
        brokenAndChippedAndCut:number;
        hullAndHusk:number;
        slightInsectDamaged:number;
    }
}
type Props = OwnProps;

const GreenGradingInfo: React.SFC<Props> = ({greenGrading}) => {
    return (
        <GreenGradingInfoBlock>
            <p>Grader: {greenGrading.grader}</p>
            <p>Grading Date: {String(greenGrading.gradingDate).slice(0,10)}</p>
            <p>measuredQuantity: {greenGrading.measuredQuantity}</p>
            <p>density: {greenGrading.density}(g/l)</p>
            <p>waterActivity: {greenGrading.waterActivity}(aw)</p>
            <p>moisture: {greenGrading.moisture}(%)</p>
            <div>
                <div>
                    <h3>Defects</h3>
                    <h5>Weight of defects: {greenGrading.defectsWeight}gram</h5>
                </div>
                <TableBox>
                    <table>
                        <thead>
                            <tr>
                                <th>full Black</th>
                                <th>full Sour</th>
                                <th>dried Cherry &Pod</th>
                                <th>fungus Damaged</th>
                                <th>severe Insect Damaged</th>
                                <th>foreign Matter</th>
                                <th>partial Black</th>
                                <th>partial Sour</th>
                                <th>parchment</th>
                                <th>floater</th>
                                <th>immature &Unripe</th>
                                <th>withered</th>
                                <th>shell</th>
                                <th>broken &Chipped &Cut</th>
                                <th>hull &Husk</th>
                                <th>slight Insect Damaged</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{greenGrading.fullBlack}</td>
                                <td>{greenGrading.fullSour}</td>
                                <td>{greenGrading.driedCherryAndPod}</td>
                                <td>{greenGrading.fungusDamaged}</td>
                                <td>{greenGrading.severeInsectDamaged}</td>
                                <td>{greenGrading.foreignMatter}</td>
                                <td>{greenGrading.partialBlack}</td>
                                <td>{greenGrading.partialSour}</td>
                                <td>{greenGrading.parchment}</td>
                                <td>{greenGrading.floater}</td>
                                <td>{greenGrading.immatureAndUnripe}</td>
                                <td>{greenGrading.withered}</td>
                                <td>{greenGrading.shell}</td>
                                <td>{greenGrading.brokenAndChippedAndCut}</td>
                                <td>{greenGrading.hullAndHusk}</td>
                                <td>{greenGrading.slightInsectDamaged}</td>
                            </tr>
                        </tbody>
                    </table>
                </TableBox>
            </div>
        </GreenGradingInfoBlock>
    )
}

const GreenGradingInfoBlock = styled.div`
    >p {

    }
    >div {
        >:first-child {
            ${flex.row};
            ${flex.jc.spaceB};
            margin: ${rem(20)} 0 ${rem(10)} 0;
            >h3 {

            }

            >h5 {

            }
        }
    }
`;

export default GreenGradingInfo;