import axios from 'axios';

const baseURL = process.env.NODE_ENV === 'development'
    ? 'http://localhost:5002/api'
    : 'https://api.c2cplatform.com/api';
const cAxios = axios.create({
    baseURL,
    headers: {
        'x-access-token': window.localStorage.getItem('signInToken')
    }
})

export default cAxios;