import React, { Component } from 'react';

import Validation from '../Validation';
import Label from '../Label';
import InputBox from '../InputBox';

import classnames from 'classnames/bind';
import styles from './Input.module.scss';
import _ from 'lodash';
const cx = classnames.bind(styles)

class Input extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        const isPropsChange = !_.isEqual(nextProps, this.props); // if same no change
        const isStateChange = !_.isEqual(nextState, this.state);
        return isPropsChange || isStateChange;
    }

    render() {
        const {
            require, name, type,
            label, error
        } = this.props;

        return (
            <div className={cx("input-wrapper")}>
                <Label require={require} label={label} />
                <InputBox {...this.props} name={name} type={type}/>
                <Validation error={error}/>
            </div>
        );
    }
}

export default Input;