import React from 'react';

import ReactPaginate from 'react-paginate';
import './Pagination.scss';

const Pagination = ({
    pageCount, handlePageChange, currentPage
}) => {
    return (
        <ReactPaginate
            previousLabel={'previous'}
            nextLabel={'next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            initialPage={currentPage}
            forcePage={currentPage}
        />
    );
};

export default Pagination;