import React, { Component } from 'react';
import PropTypes from 'prop-types';

// styles
import classnames from 'classnames/bind';
import styles from './InputBox.module.scss';

const cx = classnames.bind(styles);

class InputBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            focus: false
        }

        this.inputBoxClick = this.inputBoxClick.bind(this);
        this.inputBoxOut = this.inputBoxOut.bind(this);
    }
    inputBoxClick() {
        this.setState({
            focus: true
        });
    }

    inputBoxOut() {
        this.setState({
            focus: false
        });
    }
    
    render() {
        const {
            value, placeholder, onChange,
            icon, symbol, disabled,
            error, name, type
        } = this.props;

        const {focus} = this.state;

        let inputRef = null;
        
        return (
            <div className={cx("input-box", { disabled, focus, error })} onClick={() => {this.inputBoxClick(); inputRef.focus();}}>
                <input
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    value={value || ""}
                    disabled = {disabled}
                    onChange={(e) => {onChange(e.target.name, e.target.value)}}
                    ref={(r)=>{inputRef = r}}
                    onBlur={this.inputBoxOut}
                />
                {icon&&icon}
                {symbol&&<span>{symbol}</span>}
            </div>
        );
    }
}

InputBox.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    icon: PropTypes.any,
    symbol: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
}
export default InputBox;