import * as firebase from "firebase/app";
import 'firebase/firebase-messaging';
const firebaseConfig = {
    apiKey: "AIzaSyCq3IBnxTXt3CcwuSwt-qG7Ov33S72N_k4",
    authDomain: "test-firebase-451a0.firebaseapp.com",
    databaseURL: "https://test-firebase-451a0.firebaseio.com",
    projectId: "test-firebase-451a0",
    storageBucket: "test-firebase-451a0.appspot.com",
    messagingSenderId: "539928714017",
    appId: "1:539928714017:web:7ad7cb195639f5eef17c60"
};
  
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Retrieve Firebase Messaging object.
const messaging = firebase.messaging();

// Add the public key generated from the console here.
messaging.usePublicVapidKey("BJIXZoSkMWBx-q_IwVtO0PazwFnxOgi7B5kW-bFPrJKVRLtmxP5MJpJSKsXSgLusKZW2XsO_a7iiZYq6i8CqU1s");

export {messaging};