import { createAction, handleActions } from 'redux-actions';

const initialState = {
    activePopup: {
        isOpen: false,
        name: ""
    }
}
const OPEN_POPUP = 'modal/OPEN_POPUP';
const CLOSE_POPUP = 'modal/CLOSE_POPUP';
export const openPopup = createAction(OPEN_POPUP);
export const closePopup = createAction(CLOSE_POPUP);


export default handleActions({
    [OPEN_POPUP]: (state, action) => {
        return {
            ...state,
            activePopup : {
                isOpen: true,
                name: action.payload
            }
        }
    },

    [CLOSE_POPUP]: (state, action) => {
        return {
            ...state,
            activePopup : {
                isOpen: false,
                name: ""
            }
        }
    },

}, initialState);