import React from 'react'
import styled, { css } from 'styled-components';
import { rem, utils, flex, palette, media } from 'react-c2c-ui';

interface OwnProps {
    cuppings: {
        name:string;
        fragrance:number;
        flavor:number;
        afterTaste:number;
        acidity:number;
        body:number;
        uniformity:number;
        sweetness:number;
        cleanCup:number;
        balance:number;
        overall:number;
    }[]
    flavors: {
        flavor: string
    }[]
}
type Props = OwnProps;

const CuppingInfo: React.SFC<Props> = ({
    cuppings, flavors
}) => {
    return (
        <CuppingInfoBlock>
            <TableBox>
                <table>
                    <thead>
                        <tr>
                            <th>name</th>
                            <th>fragrance</th>
                            <th>flavor</th>
                            <th>afterTaste</th>
                            <th>acidity</th>
                            <th>body</th>
                            <th>uniformity</th>
                            <th>sweetness</th>
                            <th>cleanCup</th>
                            <th>balance</th>
                            <th>overall</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cuppings.map((item:any) => {
                            return (
                                <tr key={item.id}>
                                    <td>{item.name}</td>
                                    <td>{item.fragrance}</td>
                                    <td>{item.flavor}</td>
                                    <td>{item.afterTaste}</td>
                                    <td>{item.acidity}</td>
                                    <td>{item.body}</td>
                                    <td>{item.uniformity}</td>
                                    <td>{item.sweetness}</td>
                                    <td>{item.cleanCup}</td>
                                    <td>{item.balance}</td>
                                    <td>{item.overall}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </TableBox>
            <h5 style={{marginTop: '20px'}}>Cupping Flavors</h5>
            <div style={{display: 'flex', marginTop: '5px'}}>
                {flavors.map((item:any) => {
                    return (
                        <p key={item.id} style={{color: 'white', background: '#0082fb', padding: '5px', borderRadius:'50px', marginRight: '5px'}}>{item.flavor}</p>
                    )
                })}
            </div>
        </CuppingInfoBlock>
    )
}

const CuppingInfoBlock = styled.div`
    
`;

export const TableBox = styled.div`
    overflow-x: auto;
    >table {
        border-collapse: separate;
        border-spacing: 0;
        text-align: left;
        line-height: 1.5;
        border-top: 1px solid #ccc;
        border-left: 1px solid #ccc;

        th {
            width: 150px;
            padding: 10px;
            font-weight: bold;
            vertical-align: top;
            border-right: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            border-top: 1px solid #fff;
            border-left: 1px solid #fff;
            background: #eee;
            font-size: ${rem(12)};
            font-weight: 500;
            text-transform: capitalize;
        }
        td {
            width: 350px;
            padding: 10px;
            vertical-align: top;
            border-right: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
        }
    }
`;

export default CuppingInfo;