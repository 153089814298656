import React, { useEffect } from 'react'
import styled, { css } from 'styled-components';
import { rem, utils, flex, palette, media } from 'react-c2c-ui';
import useRequest from 'lib/hooks/useRequest';
import SampleTradeItem from './SampleTradeItem';
import { links } from 'lib/links';
import { RouteComponentProps } from 'react-router';
import nameGenerator from 'lib/nameGenerator';
import cAxios from 'lib/axiosInstance';

interface OwnProps {

}
type Props = OwnProps&RouteComponentProps;

const SampleTradeList: React.SFC<Props> = ({history}) => {
    const [
        onRequestGetSampleTradeList,
        getSampleTradeListResult,
        getSampleTradeListLoading,
        getSampleTradeListError
    ] = useRequest<any>(()=>
        cAxios.get(
            '/sample/mgmt/trades'
        )
    );
    const getSampleTradeList = async () => {
        await onRequestGetSampleTradeList();
    }

    useEffect(() => {
        getSampleTradeList();
    }, [])

    const handleStItemClick = (id: number|string) => {
        history.push(links.sampleTradeDetail.replace(':id', String(id)));
    }
    if(getSampleTradeListResult) {
        return (
            <SampleTradeListBlock>
                {getSampleTradeListResult.map((item:any) => {
                    const {
                        address={},addressId, courier, trackingNumber,
                        createdAt, id, isMgmtRead,
                        isRead, payment={}, paymentId,
                        samples=[], sentAt, status,
                        updatedAt, user={}
                    } = item;

                    const stAddress = nameGenerator.generateAddressText(
                        address.country,
                        address.city,
                        address.state,
                        address.street,
                        address.zip
                    );
                    const stBuyer = `${user.buyer.companyName}(${user.email})`;
                    const stSamples = `${
                        nameGenerator.generateShortSampleName(
                            samples[0].id,
                            samples[0].samplePack.coffee.farm.country,
                            samples[0].samplePack.coffee.farm.region,
                            samples[0].samplePack.coffee.processing
                        )
                    }${samples.length>1?` ... 외 ${samples.length-1}`:''}`;
                    const stTotalPrice = `${payment.totalPrice}USD`;
                    return (
                        <SampleTradeItem
                            tradeStatus={status}
                            tradeId={id}
                            address={stAddress}
                            buyer={stBuyer}
                            samples={stSamples}
                            totalPrice={stTotalPrice}
                            onClick={() => {handleStItemClick(id)}}
                        />
                    )
                })}
            </SampleTradeListBlock>
        )
    } else {
        if(getSampleTradeListLoading) {
            return (
                <p>...loading</p>
            )
        } else if(getSampleTradeListError) {
            return (
                <h1>{getSampleTradeListError}</h1>
            )
        } else {
            return (
                <p>Got unexpected error.</p>
            )
        }
    }
}

const SampleTradeListBlock = styled.div`
    
`;

export default SampleTradeList;